import AssignmentIcon from '@mui/icons-material/Assignment';
import DoorbellIcon from '@mui/icons-material/Doorbell';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import styled from 'styled-components';
import { ActionView } from '../../models/enum/ActionView';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import DoorbellOutlinedIcon from '@mui/icons-material/DoorbellOutlined';
import { useBrandColors } from '../../brands/useBrandTheme';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';

type Props = {
	activeView: ActionView;
	setActiveView: (activeView: ActionView) => void;
	isServiceRequestEnabled: boolean;
	hasNotifications: boolean;
};

export function BottomNavBarV1({ activeView, setActiveView, isServiceRequestEnabled, hasNotifications }: Props) {
	const { t } = useTypedTranslation();
	const colors = useBrandColors();
	const tabOrder = [
		ActionView.Dashboard,
		ActionView.Measurements,
		...(isServiceRequestEnabled ? [ActionView.Servicerequest] : []),
		...(hasNotifications ? [ActionView.Notification] : [])
	];

	const activeIndex = tabOrder.indexOf(activeView);
	const totalTabs = tabOrder.length;

	return (
		<div style={{ height: '8.5rem' }}>
			<Paper elevation={3} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
				<StyledBottomNav value={activeView} onChange={(_, newValue) => setActiveView(newValue)} showLabels>
					{totalTabs > 0 && <Indicator indicatorColor={colors.primary} position={activeIndex} totalTabs={totalTabs} />}
					<StyledBottomNavigationAction
						selectedColor={colors.primary}
						value={ActionView.Dashboard}
						label="Dashboard"
						icon={activeView === ActionView.Dashboard ? <DashboardIcon /> : <DashboardOutlinedIcon />}
					/>
					<StyledBottomNavigationAction
						selectedColor={colors.primary}
						value={ActionView.Measurements}
						label={t('footer-navigation.measurements')}
						icon={activeView === ActionView.Measurements ? <InsertChartIcon /> : <InsertChartOutlinedIcon />}
					/>
					{isServiceRequestEnabled && (
						<StyledBottomNavigationAction
							selectedColor={colors.primary}
							label={t('footer-navigation.service-request')}
							value={ActionView.Servicerequest}
							icon={<AssignmentIcon />}
						/>
					)}
					{hasNotifications && (
						<StyledBottomNavigationAction
							selectedColor={colors.primary}
							label={t('core.notification')}
							value={ActionView.Notification}
							icon={activeView === ActionView.Notification ? <DoorbellIcon /> : <DoorbellOutlinedIcon />}
						/>
					)}
				</StyledBottomNav>
			</Paper>
		</div>
	);
}

const StyledBottomNav = styled(BottomNavigation)`
	background: white;
	box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 7.5rem;
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)<{ selectedColor: string }>`
	color: #4a5a82;
	min-width: 26%;
	&.Mui-selected {
		color: ${props => props.selectedColor};
	}
	& .MuiBottomNavigationAction-label {
		font-size: 1.2rem;
	}
	& .MuiSvgIcon-root {
		font-size: 3.5rem;
	}
`;

const Indicator = styled.div<{ position: number; totalTabs: number; indicatorColor: string }>`
	position: absolute;
	top: 0;
	height: 3px;
	background-color: ${props => props.indicatorColor};
	border-radius: 2px;
	transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
	width: ${({ totalTabs }) => `calc(100% / ${totalTabs})`};
	left: ${({ position, totalTabs }) => `calc(${position} * (100% / ${totalTabs}))`};
`;
