import { useBrandColors } from '../../brands/useBrandTheme';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { SatisfactionCircle, SatisfactionSubText } from '../styles/FeelisPortal.styles';

interface Props {
	lastNegFeedback: number;
}

export function FeedbackTile({ lastNegFeedback }: Props) {
	const { t } = useTypedTranslation();
	const colors = useBrandColors();

	return (
		<>
			<SatisfactionCircle color={colors.secondary}>{lastNegFeedback}</SatisfactionCircle>
			<SatisfactionSubText>{t('user-satisfaction.no-data-info')}</SatisfactionSubText>
		</>
	);
}
