import CampaignIcon from '@mui/icons-material/Campaign';
import { TenantNotification } from '../../models/TenantNotification';
import { Column, DescText, InfoTvNotificationContainer, TitleText } from './infoTVStyledComponents';
import { getLocalizedText } from '../../locales/LocalizedText';

interface Props {
	notification: TenantNotification;
	language: string;
}

export function SingleInfoTVNotification({ notification, language }: Props) {
	const title = getLocalizedText(language, notification.title);
	const description = getLocalizedText(language, notification.description);
	return (
		<InfoTvNotificationContainer>
			<CampaignIcon style={{ fontSize: '4.5rem', color: '#D35400' }} />
			<Column>
				<TitleText>{title}</TitleText>
				{notification.description && !!notification.description.length && <DescText>{description}</DescText>}
			</Column>
		</InfoTvNotificationContainer>
	);
}
