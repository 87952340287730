import { useEffect, useState } from 'react';
import { infoTVApiFactory } from '../../api/infoTVApi';
import { useSession } from '../../stores/SessionStore';
import { ContextState, LocationType } from '../../models/context/Session';
import { InfoResponse } from '../../models/queries/QueryResponses';
import { InfoTVDefinitionModel, InfoTVType } from '../models/InfoTV';

const INFOTV_DEFINITION_STORAGE_KEY = 'infoTVDefinition';

export function useGetOrCreateInfoTVDefinition(infoTVData?: InfoResponse) {
	const { state } = useSession();

	const [infoTVDefinitionId, setInfoTVDefinitionId] = useState<string>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const api = infoTVApiFactory();

	useEffect(() => {
		async function fetchOrCreateDefinition() {
			setLoading(true);

			try {
				if (!state.locationId || !state.language || !state.locationType || !infoTVData) {
					setLoading(false);
					return;
				}

				const cachedDefinition = localStorage.getItem(INFOTV_DEFINITION_STORAGE_KEY);
				if (cachedDefinition) {
					setInfoTVDefinitionId(JSON.parse(cachedDefinition));
					setLoading(false);
					return;
				}

				const infoTVModel = buildInfoTVDefinitionModel(state, infoTVData.infoTV.locationName);
				const definitionId = await api.createInfoTVDefinitionIfNotExisted(infoTVModel);

				localStorage.setItem(INFOTV_DEFINITION_STORAGE_KEY, JSON.stringify(definitionId));
				setInfoTVDefinitionId(definitionId);
			} catch (err) {
				setError((err as Error).message);
			} finally {
				setLoading(false);
			}
		}

		fetchOrCreateDefinition();
	}, [state, infoTVData, api]);

	return { infoTVDefinitionId, loading, error };
}

function buildInfoTVDefinitionModel(state: ContextState, locationName?: string): InfoTVDefinitionModel {
	return {
		locationId: state.locationId,
		language: state.language.toLocaleUpperCase(),
		type: state.locationType === LocationType.RESET ? InfoTVType.RESET : InfoTVType.DEFAULT,
		floors: state.floors,
		description: state.floors ? `${locationName}, floor ${state.floors.join(', ')} InfoTV` : `${locationName} InfoTV`
	};
}
