export interface InfoTVData {
	locationName: string;
	feelisScore: number | null;
	co2Score: number | null;
	temperature: number;
	particleScore?: number | null;
	tvocScore?: number | null;
	lastNegFeedback: number | null;
}

export interface InfoTVDefinitionModel {
	locationId: string;
	language: string;
	type: InfoTVType;
	description?: string;
	floors?: string[];
}

export enum InfoTVType {
	DEFAULT = 'DEFAULT',
	RESET = 'RESET'
}
