import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { Backdrop, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useBrandColors } from '../../../brands/useBrandTheme';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { Question } from '../../../models/queries/QueryResponses';
import { useSession } from '../../../stores/SessionStore';
import {
	ModalContainer,
	ModalHeader,
	ModalIconWrapper,
	StyledMotionModal,
	SurveyContentWrapper
} from '../../styles/FeelisPortal.styles';
import { SurveyConfirm } from './SurveyConfirm';
import { SurveyQuestion } from './SurveyQuestion';
import { savePromptSkipTime } from './surveyUtils';

interface Props {
	open: boolean;
	onClose: () => void;
	questions: Question[];
	currentQuestion: number;
	setCurrentQuestion: (i: number) => void;
}

export default function SurveyModal({ open, onClose, questions, currentQuestion, setCurrentQuestion }: Props) {
	const colors = useBrandColors();
	const { t } = useTypedTranslation();
	const { state } = useSession();

	const [showAnswer, setShowAnswer] = useState(false);
	const [showContinuePrompt, setShowContinuePrompt] = useState(false);

	useEffect(() => {
		if (!showAnswer) return;

		const timeout = setTimeout(() => {
			setShowAnswer(false);
			if (currentQuestion === 0 && questions.length > 5) {
				// If the first question was answered, show the "Continue?" prompt
				setShowContinuePrompt(true);
			} else if (currentQuestion < questions.length - 1) {
				setCurrentQuestion(currentQuestion + 1);
			} else {
				onClose();
			}
		}, 1000);

		return () => clearTimeout(timeout);
	}, [showAnswer, currentQuestion, onClose, questions, setCurrentQuestion]);

	function handleClose(event: React.SyntheticEvent, reason: string) {
		if (reason === 'backdropClick') return;
		onClose();
	}

	function handleContinueSurvey() {
		setShowContinuePrompt(false);
		setCurrentQuestion(currentQuestion + 1);
	}

	function handleSkipSurvey() {
		savePromptSkipTime(state.premiseId);
		setCurrentQuestion(currentQuestion + 1);
		onClose();
	}

	function handleAnswerGiven() {
		setShowAnswer(true);
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			BackdropComponent={Backdrop}
			BackdropProps={{ style: { backdropFilter: 'blur(5px)' } }}>
			<StyledMotionModal
				initial={{ opacity: 0, y: 50 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -50 }}
				transition={{ duration: 0.5 }}>
				<ModalContainer>
					<ModalIconWrapper backgroundColor={colors.primary}>
						<ForumOutlinedIcon style={{ fontSize: '3rem', color: colors.secondary }} />
					</ModalIconWrapper>
					<ModalHeader backgroundColor={colors.indicators.satisfaction} />
					<SurveyContentWrapper>
						{showContinuePrompt ? (
							<SurveyConfirm
								question={t('user-satisfaction.survey-continue')}
								numOfQuestions={questions.length}
								handleContinue={handleContinueSurvey}
								handleSkip={handleSkipSurvey}
								skipButtonTitle="action.answer-later"
							/>
						) : (
							<SurveyQuestion
								currentQuestionIndex={currentQuestion}
								currentQuestion={questions[currentQuestion]}
								onClose={() => {
									savePromptSkipTime(state.premiseId);
									onClose();
								}}
								totalQuestions={questions.length}
								onAnswer={handleAnswerGiven}
								handleNextQuestion={() => setCurrentQuestion(currentQuestion + 1)}
							/>
						)}
					</SurveyContentWrapper>
				</ModalContainer>
			</StyledMotionModal>
		</Modal>
	);
}
