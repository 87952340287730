import { isEmpty } from 'lodash';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useBrandColors } from '../../../brands/useBrandTheme';
import { MeasurementData, TimeSeriesObject } from '../../../models/queries/QueryResponses';
import { renderLineAndBarGraphXLabel, renderYLabel } from '../../../utils/helperFunctions';

type LineChartProps = {
	data: MeasurementData;
	isDesktop: boolean;
};

const sortDays = (data: TimeSeriesObject[]) => {
	return data
		.filter(measurement => measurement.time !== undefined)
		.sort((a, b) => {
			return a.time - b.time;
		});
};

export function TempLineChart({ data, isDesktop }: LineChartProps) {
	const colors = useBrandColors();
	const lineColor = colors.feelisPortal.chart.line;
	const YAxisLabel = {
		value: '°C',
		fontWeight: '500',
		fill: '#8a98a7',
		fontSize: isDesktop ? '15px' : '10px',
		angle: -90,
		position: isDesktop ? 'insideLeft' : 'center'
	};

	if (!data.measurements || isEmpty(data.measurements)) return null;

	const shouldBeDynamic = data.measurements.some(
		(measurementObj: TimeSeriesObject) => measurementObj.value > 25 || measurementObj.value < 15
	);
	return (
		<ResponsiveContainer minHeight={'20rem'} width={'100%'}>
			<LineChart data={sortDays(data.measurements)} margin={{ top: 20, right: 32, left: -20 }}>
				<CartesianGrid stroke="#bfc2d5" vertical={false} />
				<XAxis
					type="number"
					scale="time"
					domain={['dataMin', 'dataMax']}
					dataKey="time"
					tickCount={data.measurements?.length}
					tick={renderLineAndBarGraphXLabel}
					padding={{ left: 10, right: 10 }}
					tickLine={false}
					axisLine={false}
					interval={0}
				/>
				<YAxis
					domain={!shouldBeDynamic ? [15, 25] : ['auto', 'auto']}
					tick={renderYLabel}
					tickLine={false}
					axisLine={false}
					tickCount={6}
					interval={0}
					label={YAxisLabel}
				/>
				<Line
					isAnimationActive={false}
					name="Temperature"
					connectNulls
					type="monotone"
					dot={false}
					strokeWidth={2}
					dataKey="value"
					stroke={lineColor}
					fill={lineColor}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
}
