import { Theme } from '../../brands/Theme';
import {
	Dashboard,
	ExternalServices,
	Measurements,
	QrCode,
	Questionnaire,
	TempQrCode
} from '../queries/QueryResponses';

export enum SessionActionTypes {
	SET_LOCATION,
	SET_PREMISE,
	SET_LANGUAGE,
	SET_TYPE,
	SET_CODE,
	SET_CODE_EXISTS,
	SET_NOTIFICATION,
	SET_QUESTIONNAIRE,
	SET_DASHBOARD,
	SET_MEASUREMENTS,
	SET_IS_DESKTOP,
	SET_EXTERNAL_SERVICES,
	SET_FLOORS,
	SET_THEME
}

export interface SetLocation {
	type: SessionActionTypes.SET_LOCATION;
	locationId: string;
}

export interface SetPremise {
	type: SessionActionTypes.SET_PREMISE;
	premiseId: string;
}

export interface SetLanguage {
	type: SessionActionTypes.SET_LANGUAGE;
	language: string;
}

export interface SetType {
	type: SessionActionTypes.SET_TYPE;
	locationType: LocationType;
}

export interface SetCode {
	type: SessionActionTypes.SET_CODE;
	code: QrCode | TempQrCode;
}

export interface SetCodeExists {
	type: SessionActionTypes.SET_CODE_EXISTS;
	codeExists: boolean | undefined;
}

export interface SetNotification {
	type: SessionActionTypes.SET_NOTIFICATION;
	showNotification: boolean;
}

export interface SetQuestionnaire {
	type: SessionActionTypes.SET_QUESTIONNAIRE;
	questionnaire: Questionnaire;
}

export interface SetDashboard {
	type: SessionActionTypes.SET_DASHBOARD;
	dashboard: Dashboard;
}

export interface SetExternalServices {
	type: SessionActionTypes.SET_EXTERNAL_SERVICES;
	externalServices: ExternalServices;
}

export interface SetMeasurements {
	type: SessionActionTypes.SET_MEASUREMENTS;
	measurements: Measurements;
}

export interface SetIsDesktop {
	type: SessionActionTypes.SET_IS_DESKTOP;
	isDekstop: boolean;
}

export interface SetFloors {
	type: SessionActionTypes.SET_FLOORS;
	floors: string[];
}

export interface SetTheme {
	type: SessionActionTypes.SET_THEME;
	theme: Theme;
}

export enum LocationType {
	DEFAULT = 'default',
	RESET = 'reset'
}

export enum InstallType {
	PREMISE,
	LOCATION
}

export enum ActivePalette {
	FREESI = 'FREESI'
}

export type SessionActions =
	| SetLocation
	| SetPremise
	| SetLanguage
	| SetType
	| SetCode
	| SetCodeExists
	| SetNotification
	| SetQuestionnaire
	| SetDashboard
	| SetMeasurements
	| SetIsDesktop
	| SetExternalServices
	| SetFloors
	| SetTheme;

export interface ContextState {
	locationId: string;
	premiseId: string | undefined;
	language: string;
	locationType: LocationType;
	code: QrCode | TempQrCode | undefined;
	codeExists: boolean | undefined;
	showNotification: boolean;
	questionnaire: Questionnaire | undefined;
	dashboard: Dashboard | undefined;
	measurements: Measurements | undefined;
	isDesktop: boolean;
	externalServices: ExternalServices | undefined;
	floors: string[] | undefined;
	theme: Theme;
}

export interface ISessionContext {
	state: ContextState;
	dispatch: React.Dispatch<SessionActions>;
}

export type ContextProviderProps = { children: React.ReactNode };
