import { locationApiFactory } from '../api/locationApi';
import { useSimpleApiQuery } from './useSimpleApiQuery';

export function useGetLocationTheme(locationId: string) {
	const {
		response: theme,
		loading,
		error
	} = useSimpleApiQuery(locationApiFactory, api => api.getLocationTheme(locationId), [locationId], {
		shouldFetch: !!locationId
	});

	return {
		theme,
		loading,
		error
	};
}
