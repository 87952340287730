import { InfoTVData, InfoTVDefinitionModel } from '../info-tv/models/InfoTV';
import { basicGet, basicPost } from './basicRestOperations';
import { feelisAxiosFactory } from './feelisAxiosFactory';

export function infoTVApiFactory() {
	const axios = feelisAxiosFactory('external');
	return {
		createInfoTVDefinitionIfNotExisted: (infoTVDefinitionModel: InfoTVDefinitionModel) =>
			basicPost<string>(axios, '/infotv', infoTVDefinitionModel),
		getInfoTVData: (definitionId: string) => basicGet<InfoTVData>(axios, `/infotv/${definitionId}/data`),
		getInfoTV: (definitionId: string) => basicGet<InfoTVDefinitionModel>(axios, `/infotv/${definitionId}`)
	};
}
