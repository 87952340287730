import { isEmpty } from 'lodash';
import { infoTVApiFactory } from '../../api/infoTVApi';
import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import { useSimpleApiQuery } from '../../hooks/useSimpleApiQuery';

export function useGetInfoTVDataV1(infoTVDefinitionId: string) {
	const refreshKey = useAutoRefresh();

	const {
		response: infoTVData,
		loading,
		error
	} = useSimpleApiQuery(
		infoTVApiFactory,
		api => api.getInfoTVData(infoTVDefinitionId),
		[infoTVDefinitionId, refreshKey],
		{
			shouldFetch: !isEmpty(infoTVDefinitionId)
		}
	);

	return {
		infoTVData,
		loading,
		error
	};
}
