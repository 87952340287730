import QrCodeIcon from '@mui/icons-material/QrCode';
import { Checkbox } from '@mui/material';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { useWithFallbackContent } from '../../hooks/useWithFallbackContent';
import locations from '../../images/locations.svg';
import premises from '../../images/premises.svg';
import { ActivePage } from '../../models/Feelis';
import { LocalizedString } from '../../models/LocalizedString';
import { NewLocation } from '../../models/context/Install';
import { setDisableState, useInstall } from '../../stores/InstallStore';
import { SummaryContainer } from '../../styles/styled-components/containers';
import { MobileIcon } from '../../styles/styled-components/icons';
import { CheckboxRow, SummaryContentRow, SummaryRow } from '../../styles/styled-components/rows';
import {
	SelectionTitle,
	SummaryContentKey,
	SummaryContentValue,
	SummaryRowTitle
} from '../../styles/styled-components/text';
import { AnimatedContainer } from '../../views/install/AnimatedContainer';

interface Props {
	page: ActivePage;
}

type NewLocationType = { id: string; name: LocalizedString };

export function Summary({ page }: Props) {
	const { t, unsafeTranslation } = useTypedTranslation();
	const { state, dispatch } = useInstall();
	const withFallback = useWithFallbackContent();

	function newTypes(data: NewLocation) {
		return data ? data.types.map((type: NewLocationType) => withFallback(type.name)).join(', ') : '';
	}

	const confirmSummary = () => {
		dispatch(setDisableState(!state.disableState));
	};

	return (
		<SummaryContainer>
			<SelectionTitle>{unsafeTranslation(page.title)}</SelectionTitle>
			<SummaryRow>
				<QrCodeIcon />
				<SummaryRowTitle>{t('selection.summary.qr-code')}</SummaryRowTitle>
			</SummaryRow>
			<SummaryContentRow>
				<SummaryContentKey>{t('selection.summary.qr-code-input')}</SummaryContentKey>
				{<SummaryContentValue>{window.localStorage.getItem('identifier')}</SummaryContentValue>}
			</SummaryContentRow>
			<SummaryRow>
				<MobileIcon height="1.2em" src={locations} />
				<SummaryRowTitle>{t('selection.summary.location')}</SummaryRowTitle>
			</SummaryRow>
			<AnimatedContainer>
				<SummaryContentRow>
					<SummaryContentKey>{t('location.name')}</SummaryContentKey>
					{state.location && <SummaryContentValue>{state.location.name}</SummaryContentValue>}
					{state.newLocation && <SummaryContentValue>{state.newLocation.name}</SummaryContentValue>}
				</SummaryContentRow>
				{state.newLocation && (
					<SummaryContentRow>
						<SummaryContentKey>{t('location.type')}</SummaryContentKey>
						<SummaryContentValue>{newTypes(state.newLocation)}</SummaryContentValue>
					</SummaryContentRow>
				)}
			</AnimatedContainer>
			<SummaryRow>
				<MobileIcon height="1.2em" src={premises} />
				<SummaryRowTitle>{t('selection.summary.premise')}</SummaryRowTitle>
			</SummaryRow>
			<AnimatedContainer>
				{state.newPremise && state.newPremise.namePrefix && (
					<SummaryContentRow>
						<SummaryContentKey>{t('selection.premise.name-prefix')}</SummaryContentKey>
						<SummaryContentValue>{state.newPremise.namePrefix}</SummaryContentValue>
					</SummaryContentRow>
				)}
				{state.newPremise && state.newPremise.nameSuffix && (
					<SummaryContentRow>
						<SummaryContentKey>{t('selection.premise.name-suffix')}</SummaryContentKey>
						<SummaryContentValue>{state.newPremise.nameSuffix}</SummaryContentValue>
					</SummaryContentRow>
				)}
				{state.premise && (
					<SummaryContentRow>
						<SummaryContentKey>{t('selection.premise.name-suffix')}</SummaryContentKey>
						<SummaryContentValue>{state.premise.name}</SummaryContentValue>
					</SummaryContentRow>
				)}
				{state.newPremise && (
					<>
						<SummaryContentRow>
							<SummaryContentKey>{t('location.type')}</SummaryContentKey>
							<SummaryContentValue>{withFallback(state.newPremise.type.name)}</SummaryContentValue>
						</SummaryContentRow>
						<SummaryContentRow>
							<SummaryContentKey>{t('location.floor')}</SummaryContentKey>
							<SummaryContentValue>{state.newPremise.floor ?? '-'}</SummaryContentValue>
						</SummaryContentRow>
					</>
				)}
			</AnimatedContainer>
			<CheckboxRow>
				<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }} onClick={confirmSummary} />
				<p style={{ fontSize: '1.4rem' }}>{t('selection.summary.confirm')}</p>
			</CheckboxRow>
		</SummaryContainer>
	);
}
