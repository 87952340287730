import { useBrandColors } from '../brands/useBrandTheme';
import { useTypedTranslation } from '../hooks/useTypedTranslation';
import { ReactComponent as TemperatureIcon } from '../images/temp.svg';
import { ReactComponent as VentilationIcon } from '../images/ventilation.svg';
import { ScoreType } from '../models/Feelis';
import { useSession } from '../stores/SessionStore';
import { scoreMapper } from '../views/infotv/infoHelper';
import { MeasurementTile } from './components/MeasurementTile';
import { SatisfactionWrapper } from './components/SatisfactionWrapper';
import { DashboardContainer } from './styles/FeelisPortal.styles';

export function DashboardViewV1() {
	const { t, unsafeTranslation } = useTypedTranslation();
	const { state } = useSession();
	const colors = useBrandColors();

	if (!state.dashboard) return null;
	const { temperature, co2Score, feelisPercentageScoreAll, feelisPercentageScoreMonth } = state.dashboard;
	const hasOnlyOneMeasurement = !temperature || typeof co2Score !== 'number';
	return (
		<>
			<DashboardContainer backgroundColor={colors.feelisPortal.backgroundColor}>
				{typeof temperature === 'number' && (
					<MeasurementTile
						name={t('core.temperature')}
						value={temperature + ' °C'}
						icon={TemperatureIcon}
						backgroundColor={colors.indicators.temperature}
						fontColor={colors.feelisPortal.lightFontColor}
					/>
				)}
				{typeof co2Score === 'number' && (
					<MeasurementTile
						name={t('core.ventilation')}
						value={unsafeTranslation(scoreMapper(co2Score, ScoreType.VENTILATION))}
						icon={VentilationIcon}
						backgroundColor={colors.indicators.ventilation}
						fontColor={colors.feelisPortal.darkFontColor}
					/>
				)}
				{(!!feelisPercentageScoreAll || !!feelisPercentageScoreMonth) && (
					<div
						style={{
							display: 'flex',
							flexDirection: hasOnlyOneMeasurement ? 'column' : 'row',
							gap: '1rem',
							flexGrow: 1
						}}>
						{!!feelisPercentageScoreAll && (
							<SatisfactionWrapper value={feelisPercentageScoreAll} timeRange={t('user-satisfaction.all-time')} />
						)}
						{!!feelisPercentageScoreMonth && (
							<SatisfactionWrapper value={feelisPercentageScoreMonth} timeRange={t('user-satisfaction.month')} />
						)}
					</div>
				)}
			</DashboardContainer>
		</>
	);
}
