import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { NoUserSatisfactionContainer } from '../../../styles/styled-components/containers';
import { SatisfactionCircle, SatisfactionInnerCircle } from '../../../styles/styled-components/misc';
import { SatisfactionTileText } from '../../../styles/styled-components/text';

type FeedbackTileProps = {
	isDesktop: boolean;
	lastNegFeedback: number;
};

export function FeedbackTile({ isDesktop, lastNegFeedback }: FeedbackTileProps) {
	const { t } = useTypedTranslation();

	return (
		<NoUserSatisfactionContainer>
			<SatisfactionCircle mobile={!isDesktop}>
				<SatisfactionInnerCircle>{lastNegFeedback}</SatisfactionInnerCircle>
			</SatisfactionCircle>
			<SatisfactionTileText>{t('user-satisfaction.no-data-info')}</SatisfactionTileText>
		</NoUserSatisfactionContainer>
	);
}
