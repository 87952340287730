import { useBrandColors } from '../../brands/useBrandTheme';
import { PrimaryButton } from '../../styles-v1/buttons';

interface Props {
	onClick: () => void;
	label: string;
	secondary?: boolean;
}

export function FreesiButton({ onClick, label, secondary }: Props) {
	const colors = useBrandColors();

	return (
		<PrimaryButton
			backgroundColor={secondary ? colors.button.secondary.backgroundColor : colors.button.primary.backgroundColor}
			fontColor={secondary ? colors.button.secondary.fontColor : colors.button.primary.fontColor}
			onClick={onClick}>
			{label}
		</PrimaryButton>
	);
}
