import { useEffect } from 'react';
import { Header } from '../../components/header/Header';
import { MobileTempTile } from '../../components/tile/temperature/MobileTempTile';
import { TemperatureTile } from '../../components/tile/temperature/TemperatureTile';
import { DelaySpinner } from '../../components/util/DelaySpinner';
import { useGetInfoTvData } from '../../hooks/useGetInfoTvData';
import { useWindowResize } from '../../hooks/useWindowResize';
import { useGetInfoTVNotificationsV1 } from '../../info-tv/hooks/useGetInfoTVNotificationsV1';
import { useGetOrCreateInfoTVDefinition } from '../../info-tv/hooks/useGetOrCreateInfoTVDefinition';
import { setIsDesktop, useSession } from '../../stores/SessionStore';
import { FlexContainer, InfoTvContainer } from '../../styles/styled-components/containers';
import { InfoTVError } from '../error/InfoTVError';
import { InfoMeasurementColumn } from './InfoMeasurementColumn';
import { InfoTVNotifications } from './InfoTVNotifications';

export function InfoView() {
	const { windowWidth } = useWindowResize();
	const isDesktop = windowWidth >= 1024;
	const { state, dispatch } = useSession();
	const { data, loading, error } = useGetInfoTvData(state.locationId, state.floors);

	const { infoTVDefinitionId } = useGetOrCreateInfoTVDefinition(data);
	const { infoTVNotifications } = useGetInfoTVNotificationsV1(infoTVDefinitionId ?? '');

	useEffect(() => {
		dispatch(setIsDesktop(isDesktop));
	}, [dispatch, isDesktop]);

	if (loading) return <DelaySpinner delay={30} />;
	if (!loading && error && !data) return <InfoTVError />;

	const activeInfoTVNotifications = infoTVNotifications?.filter(n => n.active);

	return (
		<FlexContainer>
			<Header isDesktop={isDesktop} showLanguagePicker={false} locationName={data?.infoTV.locationName} />
			{!!activeInfoTVNotifications?.length && state.language && (
				<InfoTVNotifications notifications={activeInfoTVNotifications} language={state.language} />
			)}
			<InfoTvContainer mobile={!isDesktop}>
				{data?.infoTV.temperature && isDesktop && (
					<TemperatureTile data={data.infoTV.temperature} floors={state.floors} />
				)}
				{data?.infoTV.temperature && !isDesktop && <MobileTempTile data={data.infoTV.temperature} />}
				{data && <InfoMeasurementColumn isDesktop={state.isDesktop} data={data} locationType={state.locationType} />}
			</InfoTvContainer>
		</FlexContainer>
	);
}
