import { useTypedTranslation } from './useTypedTranslation';
import enLocale from 'date-fns/locale/en-US';
import fiLocale from 'date-fns/locale/fi';
import deLocale from 'date-fns/locale/de';
import svLocale from 'date-fns/locale/sv';
import frLocale from 'date-fns/locale/fr';
import esLocale from 'date-fns/locale/es';
import nlLocale from 'date-fns/locale/nl';

import { defaultLocale, supportedLocales } from '../i18n/getLocaleStrings';
import { localeMap } from '../utils/i18nUtils';

const locales = {
	en: enLocale,
	fi: fiLocale,
	sv: svLocale,
	de: deLocale,
	fr: frLocale,
	es: esLocale,
	nl: nlLocale
};

const dateMaskMap = localeMap(() => '__.__.____');
const dateTimeMaskMap = localeMap(() => '__.__.____ __:__');

const dateFormatMap = localeMap(() => 'dd.MM.yyyy');
const dateTimeFormatMap = localeMap(() => 'dd.MM.yyyy HH:mm');

export default function useLocale() {
	const { language } = useTypedTranslation();
	const localeKey = supportedLocales.includes(language) ? language : defaultLocale;
	const locale = locales[localeKey];

	return {
		localeKey,
		locale,
		dateMask: dateMaskMap[localeKey],
		dateTimeMask: dateTimeMaskMap[localeKey],
		dateFormat: dateFormatMap[localeKey],
		dateTimeFormat: dateTimeFormatMap[localeKey]
	};
}
