import { ReactComponent as Happy } from '../images/bear-happy.svg';
import { ReactComponent as Neutral } from '../images/bear-neutral.svg';
import { ReactComponent as Sad } from '../images/bear-sad.svg';
import { ReactComponent as VHappy } from '../images/bear-vhappy.svg';
import { ReactComponent as VSad } from '../images/bear-vsad.svg';
import { ReactComponent as Cold } from '../images/cold.svg';
import { ReactComponent as Freezing } from '../images/freezing1.svg';
import { ReactComponent as Hot } from '../images/hot1.svg';
import { ReactComponent as Perfect } from '../images/perfect.svg';
import { ReactComponent as Warm } from '../images/warm.svg';

export function iconChooser(optionValue: number, numberOfOptions?: number, isTempQuestion?: boolean) {
	const iconSet = isTempQuestion ? tempIconSet : getQuestionIconSet(numberOfOptions);

	if (optionValue >= 0 && optionValue <= 4) {
		return iconSet[optionValue];
	}
	throw new Error(`Invalid icon value for user satisfaction: ${optionValue}`);
}

const tempIconSet = [Freezing, Cold, Perfect, Warm, Hot];

function getQuestionIconSet(numberOfOptions: number | undefined) {
	if (numberOfOptions === 3) {
		// when question has 3 options, min-max icon is the same sad icon
		return [Sad, Neutral, Sad];
	} else if (numberOfOptions === 4) {
		// when question has 4 options, skip the second icon
		return [VSad, Neutral, Happy, VHappy];
	} else {
		return [VSad, Sad, Neutral, Happy, VHappy];
	}
}
