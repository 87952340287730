import { useEffect, useState } from 'react';
import { TenantNotification } from '../../models/TenantNotification';

export function useGetUnseenNotifications(notifications?: TenantNotification[]) {
	const [unseenNotifications, setUnseenNotifications] = useState<TenantNotification[]>([]);

	useEffect(() => {
		if (!notifications || notifications.length === 0) return;
		const seenNotifications = JSON.parse(localStorage.getItem('seenNotifications') || '[]');
		const activeNotifications = notifications.filter(n => n.active);
		const newNotifications = activeNotifications.filter(n => !seenNotifications.includes(n.id));
		setUnseenNotifications(newNotifications);
	}, [notifications]);

	return { unseenNotifications };
}
