import { useCallback, useEffect, useState } from 'react';
import { ScoreConfig } from '../components/tile/score/ScoreTile';
import particles from '../images/particles.svg';
import tvoc from '../images/tvoc.svg';
import ventilation from '../images/ventilation.svg';
import { ScoreType } from '../models/Feelis';
import { iconMapper } from '../views/infotv/infoHelper';

export function useScoreConfigMapper(type: ScoreType, isDashboard: boolean | undefined, data: number | null) {
	const [config, setConfig] = useState<ScoreConfig>();

	const configMapper = useCallback(
		(type: ScoreType) => {
			const configMap = {
				[ScoreType.VENTILATION]: () => ({
					icon: ventilation,
					color: isDashboard ? '#C599F1' : '#DFFDF2',
					text: 'core.ventilation'
				}),
				[ScoreType.TVOC]: () => ({
					icon: tvoc,
					color: '#C599F1',
					text: 'core.tvoc'
				}),
				[ScoreType.PARTICULATE]: () => ({
					icon: particles,
					color: '#DCC2F7',
					text: 'core.particles'
				}),
				[ScoreType.SATISFACTION]: () => ({
					icon: data ? iconMapper(data) : null,
					color: '#A0F9D9',
					text: 'user-satisfaction.root'
				}),
				[ScoreType.SATISFACTION_NO_FEEDBACK]: () => ({
					icon: null,
					color: '#A0F9D9',
					text: 'user-satisfaction.root'
				})
			};
			const configFunction = configMap[type];
			if (!configFunction) {
				throw new Error(`Invalid score type ${type} ${data}`);
			}
			return configFunction();
		},
		[data, isDashboard]
	);

	useEffect(() => {
		if (typeof type === 'number') {
			setConfig(configMapper(type));
		}
	}, [type, configMapper]);

	return { config };
}
