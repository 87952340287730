import { useParams } from 'react-router';
import { useBrandColors } from '../brands/useBrandTheme';
import { DelaySpinner } from '../components/util/DelaySpinner';
import { useTypedTranslation } from '../hooks/useTypedTranslation';
import { ReactComponent as ParticulatesIcon } from '../images/particles.svg';
import { ReactComponent as TVOCIcon } from '../images/tvoc.svg';
import { ReactComponent as VentilationIcon } from '../images/ventilation.svg';
import { ScoreType } from '../models/Feelis';
import { FlexColumn, FlexRow } from '../styles-v1/containers';
import { iconChooser } from '../utils/iconFromValue';
import { InfoTVError } from '../views/error/InfoTVError';
import { scoreMapper } from '../views/infotv/infoHelper';
import { InfoTVHeader } from './components/InfoTVHeader';
import { FeedbackTile } from './components/indicator-tile/FeedbackTile';
import { IndicatorTile } from './components/indicator-tile/IndicatorTile';
import { TemperatureTile } from './components/indicator-tile/TemperatureTile';
import { NotificationCarousel } from './components/NotificationsCarousel';
import { useGetInfoTVDataV1 } from './hooks/useGetInfoTVDataV1';
import { useGetInfoTVDefinition } from './hooks/useGetInfoTVDefinition';
import { useGetInfoTVNotificationsV1 } from './hooks/useGetInfoTVNotificationsV1';
import { InfoTVType } from './models/InfoTV';
import { InfoTVContainer } from './styles/InfoTV.styles';

export function InfoTVViewV1() {
	const { t, unsafeTranslation } = useTypedTranslation();
	const colors = useBrandColors();
	const { definitionId } = useParams() as {
		definitionId: string;
	};

	const { infoTV } = useGetInfoTVDefinition(definitionId);
	const { infoTVData, loading, error } = useGetInfoTVDataV1(definitionId);
	const { infoTVNotifications } = useGetInfoTVNotificationsV1(definitionId);
	const activeNotifications = infoTVNotifications?.filter(n => n.active);
	const notificationExists = activeNotifications && !!activeNotifications.length;

	if (loading) return <DelaySpinner delay={30} />;
	if (!loading && error) return <InfoTVError />;
	if (!infoTV || !infoTVData) return null;

	return (
		<InfoTVContainer infoTVColors={colors.infoTV}>
			<InfoTVHeader locationName={infoTVData.locationName} floors={infoTV?.floors} />
			<FlexRow
				style={{ height: notificationExists ? '75%' : '100%', gap: '15px', padding: '0 15px 15px 15px', flex: 1 }}>
				{typeof infoTVData.temperature === 'number' && (
					<TemperatureTile temperature={infoTVData.temperature} floors={infoTV.floors} />
				)}
				{infoTV.type === InfoTVType.RESET &&
					(typeof infoTVData.tvocScore === 'number' || typeof infoTVData.particleScore === 'number') && (
						<FlexColumn style={{ gap: '15px', flex: 1 }}>
							{typeof infoTVData.particleScore === 'number' && (
								<IndicatorTile
									value={unsafeTranslation(scoreMapper(infoTVData.particleScore, ScoreType.PARTICULATE))}
									name={t('core.particles')}
									icon={ParticulatesIcon}
									backgroundColor={colors.indicators.particulate}
									small
								/>
							)}
							{typeof infoTVData.tvocScore === 'number' && (
								<IndicatorTile
									value={unsafeTranslation(scoreMapper(infoTVData.tvocScore, ScoreType.TVOC))}
									name={t('core.tvoc')}
									icon={TVOCIcon}
									backgroundColor={colors.indicators.tvoc}
									small
								/>
							)}
						</FlexColumn>
					)}
				{typeof infoTVData.co2Score === 'number' && (
					<IndicatorTile
						value={unsafeTranslation(scoreMapper(infoTVData.co2Score, ScoreType.VENTILATION))}
						name={t('core.ventilation')}
						icon={VentilationIcon}
						backgroundColor={colors.indicators.ventilation}
					/>
				)}
				{typeof infoTVData.feelisScore === 'number' && (
					<IndicatorTile
						value={unsafeTranslation(scoreMapper(infoTVData.feelisScore, ScoreType.SATISFACTION))}
						name={t('user-satisfaction.root')}
						icon={iconChooser(infoTVData.feelisScore)}
						backgroundColor={colors.indicators.satisfaction}
					/>
				)}
				{typeof infoTVData.lastNegFeedback === 'number' && (
					<FeedbackTile value={infoTVData.lastNegFeedback.toString()} />
				)}
			</FlexRow>
			{notificationExists && <NotificationCarousel notifications={activeNotifications} />}
		</InfoTVContainer>
	);
}
