import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { IconWrapper, IndicatorNameText, IndicatorValueText, Tile } from '../../styles/InfoTV.styles';
import { ReactComponent as VHappyIcon } from '../../../images/bear-vhappy.svg';
import { FlexColumn } from '../../../styles-v1/containers';
import { useBrandTheme } from '../../../brands/useBrandTheme';

interface Props {
	value: string;
}

export function FeedbackTile({ value }: Props) {
	const { themeStyles } = useBrandTheme();
	const { t } = useTypedTranslation();

	return (
		<Tile
			backgroundColor={themeStyles.colors.indicators.satisfaction}
			fontColor={themeStyles.colors.infoTV.darkFontColor}>
			<IconWrapper>
				<VHappyIcon fill={themeStyles.colors.infoTV.darkFontColor} />
			</IconWrapper>
			<FlexColumn style={{ gap: '10px' }}>
				<IndicatorValueText fonts={themeStyles.typography}>{value}</IndicatorValueText>
				<IndicatorNameText>{t('user-satisfaction.no-data-info')}</IndicatorNameText>
			</FlexColumn>
		</Tile>
	);
}
