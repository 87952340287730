import { useBrandTheme } from '../../brands/useBrandTheme';
import { HeaderLogo } from '../../components/header/HeaderLogo';
import { FlexColumn } from '../../styles-v1/containers';
import { FlexRow } from '../../styles/styled-components/rows';
import { LocationName, SubTitle } from '../styles/InfoTV.styles';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';

interface Props {
	locationName: string;
	floors?: string[];
}

export function InfoTVHeader({ locationName, floors }: Props) {
	const { t } = useTypedTranslation();
	const { themeStyles } = useBrandTheme();

	return (
		<FlexRow style={{ justifyContent: 'space-between', padding: '10px 30px 0 30px', alignItems: 'center' }}>
			<HeaderLogo secondary width="18em" />
			<FlexColumn style={{ alignItems: 'flex-end' }}>
				<LocationName fonts={themeStyles.typography} color={themeStyles.colors.infoTV.lightFontColor}>
					{locationName}
				</LocationName>
				<SubTitle color={themeStyles.colors.infoTV.lightFontColor}>
					{floors && !!floors.length ? t('location.floor') + ': ' + floors.join(', ') : t('core.indoor-air')}
				</SubTitle>
			</FlexColumn>
		</FlexRow>
	);
}
