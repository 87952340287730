import styled, { keyframes } from 'styled-components';
import { FlexColumn } from '../../styles-v1/containers';
import { ThemeTypography } from '../../brands/Theme';
import { Box } from '@mui/material';

type ColorProps = {
	infoTVColors: {
		topBackgroundColor: string;
		bottomBackgroundColor: string;
	};
};

export const InfoTVContainer = styled(FlexColumn)<ColorProps>`
	background: linear-gradient(
		135deg,
		${({ infoTVColors }) => infoTVColors.topBackgroundColor},
		${({ infoTVColors }) => infoTVColors.bottomBackgroundColor}
	);
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
`;

type FontProps = {
	fonts: ThemeTypography;
	color: string;
};

export const LocationName = styled.span<FontProps>`
	font-family: ${props => props.fonts.fontSecondary};
	font-size: 3.5rem;
	color: ${props => props.color};
`;

export const SubTitle = styled.span<{ color: string }>`
	font-size: 3rem;
	color: ${props => props.color};
`;

type TileProps = {
	backgroundColor: string;
	fontColor: string;
};

export const Tile = styled(FlexColumn)<TileProps>`
	background: ${props => props.backgroundColor};
	color: ${props => props.fontColor};
	border-radius: 8px;
	flex: 1;
	min-width: 200px;
	height: 100%;
	justify-content: space-between;
	align-items: flex-end;
	padding: 40px;
	box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.2);
`;

export const TemperatureText = styled.span<{ fonts: ThemeTypography }>`
	font-family: ${props => props.fonts.fontSecondary};
	font-size: 7rem;
	text-align: end;
`;

export const IconWrapper = styled.div<{ small?: boolean }>`
	display: flex;
	height: ${props => (props.small ? '3em' : '6em')};
	width: ${props => (props.small ? '3em' : '6em')};

	svg {
		width: 100%;
		height: 100%;
	}
`;

export const IndicatorNameText = styled.span`
	font-size: 4rem;
	text-align: end;
	word-break: break-word;
`;

export const IndicatorValueText = styled.span<{ fonts: ThemeTypography; small?: boolean }>`
	font-family: ${props => props.fonts.fontSecondary};
	font-size: ${props => (props.small ? '4.5rem' : '5.5rem')};
	text-align: end;
`;

const slideOutLeft = keyframes`
  0% { transform: translateX(0); opacity: 1; }
  100% { transform: translateX(-100%); opacity: 0; }
`;

const slideInRight = keyframes`
  0% { transform: translateX(100%); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
`;

export const CarouselContainer = styled.div`
	display: flex;
	width: 100%;
	padding: 0 15px;
	height: 12%;
	margin-bottom: 15px;
`;

export const SlideWrapper = styled.div<{ isSliding: boolean; colors: TileProps }>`
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: 10px;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
	background-color: ${props => props.colors.backgroundColor};
	color: ${props => props.colors.fontColor};
	padding: 10px;
	font-size: 3rem;
	animation: ${({ isSliding }) => (isSliding ? slideOutLeft : slideInRight)} 1s ease-in-out;
	box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.2);
`;

export const NotificationIcon = styled(Box)<{ backgroundColor: string }>`
	background-color: ${props => props.backgroundColor};
	width: 2em;
	height: 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
`;
