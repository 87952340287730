import styled from 'styled-components';
import { headingText__Mobile } from '../globalStyles';
import { GraphTileInfoBoxProps, MobileProps, PageCircleProps } from '../interfaces';

/* <---- OTHER COMPONENTS */
export const DayCounterCircle = styled('div')`
	width: 13em;
	height: 13em;
	border-radius: 50%;
	background: #a0f9d9;
	border: solid 4px #00195a;
	position: relative;
	margin-top: 1rem;
	margin-bottom: 1rem;

	@media (max-height: 1400px) and (max-width: 1500px) {
		width: 8rem;
		height: 8em;
	}
	@media (max-height: 800px) {
		width: 10rem;
		height: 10em;
	}
`;

export const Location = styled('div')<MobileProps>`
	display: flex;
	flex-direction: column;
	align-items: ${props => (props.mobile ? 'flex-start' : 'initial')};
	width: auto;
	margin-top: 1rem;
	margin-bottom: 2rem;
	padding-right: 1.6rem;
`;

export const GraphTileInfoSpacer = styled('span')`
	width: 20%;
`;

export const PageCircle = styled('div')<PageCircleProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 27px;
	width: 27px;
	border: ${props => (props.active ? '1px solid #171A2B' : '1px solid #a6a6a6')};
	border-radius: 50%;
	margin-right: 1em;

	> span {
		font-size: 1.1rem;
		color: ${props => (props.active ? '#383838' : '#a6a6a6')};
	}
`;

export const SatisfactionCircle = styled('div')<MobileProps>`
	width: 8rem;
	height: 8rem;
	border-radius: 50%;
	background: ${props => (props.type === 'CO2' ? '#F5EBFC' : props.type === 'TEMP' ? '#f5f6ff' : 'initial')};
	border: solid 2px rgb(45, 242, 172);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.6rem;
`;

export const SatisfactionInnerCircle = styled('span')`
	${headingText__Mobile};
	color: #00195a;
	letter-spacing: 1.5px;
`;

export const QuestionWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const GraphTileInfoBox = styled('div')<GraphTileInfoBoxProps>`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const ErrorViewMainContent = styled('div')`
	display: flex;
	flex-direction: column;
	height: 300px;
	width: 75%;
	text-align: center;
	align-items: center;
	justify-content: space-between;
`;

export const ErrorViewContent = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ErrorViewCircle = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 130px;
	width: 130px;
	border-radius: 50%;
	border: 3px solid #ff5a73;
	margin-bottom: 1.5em;
`;
/* OTHER COMPONENTS ----> */
