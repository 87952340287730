import { Campaign } from '@mui/icons-material';
import { useBrandColors } from '../../brands/useBrandTheme';
import { getLocalizedText } from '../../locales/LocalizedText';
import { NotificationType, TenantNotification } from '../../models/TenantNotification';
import { FlexColumn } from '../../styles-v1/containers';
import { NotificationIcon, SlideWrapper } from '../styles/InfoTV.styles';
import { ThemeNotification } from '../../brands/Theme';

interface Props {
	notification: TenantNotification;
	currentLocale: string;
	isSliding: boolean;
}

export function NotificationTile({ notification, currentLocale, isSliding }: Props) {
	const { notification: notificationColors } = useBrandColors();

	const title = getLocalizedText(currentLocale, notification.title);
	const description = getLocalizedText(currentLocale, notification.description);
	const colors = getNotificationColor(notification, notificationColors);

	return (
		<SlideWrapper
			isSliding={isSliding}
			key={notification.id}
			colors={{ backgroundColor: colors.backgroundColor, fontColor: colors.fontColor }}>
			<NotificationIcon backgroundColor={colors.iconBackground}>
				<Campaign style={{ fontSize: '4rem', color: colors.iconColor }} />
			</NotificationIcon>
			<FlexColumn>
				<span style={{ fontSize: '3.5rem', fontWeight: '500' }}>{title}</span>
				<span style={{ fontSize: '3rem' }}>{description}</span>
			</FlexColumn>
		</SlideWrapper>
	);
}

export function getNotificationColor(notification: TenantNotification, colors: ThemeNotification) {
	return notification.type === NotificationType.SUCCESS ? colors.success : colors.info;
}
