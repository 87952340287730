import { createContext, useContext, useReducer } from 'react';
import { defaultTheme } from '../brands/brandUtilities';
import { Theme } from '../brands/Theme';
import {
	ContextProviderProps,
	ContextState,
	ISessionContext,
	LocationType,
	SessionActions,
	SessionActionTypes,
	SetCode,
	SetCodeExists,
	SetDashboard,
	SetExternalServices,
	SetFloors,
	SetIsDesktop,
	SetLanguage,
	SetLocation,
	SetMeasurements,
	SetNotification,
	SetPremise,
	SetQuestionnaire,
	SetTheme,
	SetType
} from '../models/context/Session';
import {
	Dashboard,
	ExternalServices,
	Measurements,
	QrCode,
	Questionnaire,
	TempQrCode
} from '../models/queries/QueryResponses';

const initialState: ContextState = {
	locationId: '',
	premiseId: undefined,
	language: 'fi',
	locationType: LocationType.DEFAULT,
	code: undefined,
	codeExists: undefined,
	showNotification: true,
	questionnaire: undefined,
	dashboard: undefined,
	measurements: undefined,
	isDesktop: false,
	externalServices: undefined,
	floors: undefined,
	theme: defaultTheme
};

const reducer = (previousState: ContextState, action: SessionActions) => {
	switch (action.type) {
		case SessionActionTypes.SET_LOCATION:
			return { ...previousState, locationId: action.locationId };
		case SessionActionTypes.SET_PREMISE:
			return { ...previousState, premiseId: action.premiseId };
		case SessionActionTypes.SET_LANGUAGE:
			return { ...previousState, language: action.language };
		case SessionActionTypes.SET_TYPE:
			return { ...previousState, locationType: action.locationType };
		case SessionActionTypes.SET_CODE:
			return { ...previousState, code: action.code };
		case SessionActionTypes.SET_CODE_EXISTS:
			return { ...previousState, codeExists: action.codeExists };
		case SessionActionTypes.SET_NOTIFICATION:
			return { ...previousState, showNotification: action.showNotification };
		case SessionActionTypes.SET_QUESTIONNAIRE:
			return { ...previousState, questionnaire: action.questionnaire };
		case SessionActionTypes.SET_DASHBOARD:
			return { ...previousState, dashboard: action.dashboard };
		case SessionActionTypes.SET_MEASUREMENTS:
			return { ...previousState, measurements: action.measurements };
		case SessionActionTypes.SET_IS_DESKTOP:
			return { ...previousState, isDesktop: action.isDekstop };
		case SessionActionTypes.SET_EXTERNAL_SERVICES:
			return { ...previousState, externalServices: action.externalServices };
		case SessionActionTypes.SET_FLOORS:
			return { ...previousState, floors: action.floors };
		case SessionActionTypes.SET_THEME:
			return { ...previousState, theme: action.theme };
		default:
			throw new Error('Reducer error: unhandled action type');
	}
};

// helper functions to make dispatch calling simpler
// -------------------------------------------------
export const setLocation = (id: string): SetLocation => ({
	type: SessionActionTypes.SET_LOCATION,
	locationId: id
});

export const setPremise = (id: string): SetPremise => ({
	type: SessionActionTypes.SET_PREMISE,
	premiseId: id
});

export const setLanguage = (lang: string): SetLanguage => ({
	type: SessionActionTypes.SET_LANGUAGE,
	language: lang
});

export const setType = (locationType: LocationType): SetType => ({
	type: SessionActionTypes.SET_TYPE,
	locationType: locationType
});

export const setCode = (code: QrCode | TempQrCode): SetCode => ({
	type: SessionActionTypes.SET_CODE,
	code: code
});

export const setCodeExists = (codeExists: boolean | undefined): SetCodeExists => ({
	type: SessionActionTypes.SET_CODE_EXISTS,
	codeExists: codeExists
});

export const setNotification = (showNotification: boolean): SetNotification => ({
	type: SessionActionTypes.SET_NOTIFICATION,
	showNotification: showNotification
});

export const setQuestionnaire = (questionnaire: Questionnaire): SetQuestionnaire => ({
	type: SessionActionTypes.SET_QUESTIONNAIRE,
	questionnaire: questionnaire
});

export const setDashboard = (dashboard: Dashboard): SetDashboard => ({
	type: SessionActionTypes.SET_DASHBOARD,
	dashboard: dashboard
});

export const setMeasurements = (measurements: Measurements): SetMeasurements => ({
	type: SessionActionTypes.SET_MEASUREMENTS,
	measurements: measurements
});

export const setIsDesktop = (isDesktop: boolean): SetIsDesktop => ({
	type: SessionActionTypes.SET_IS_DESKTOP,
	isDekstop: isDesktop
});

export const setTheme = (theme: Theme): SetTheme => ({
	type: SessionActionTypes.SET_THEME,
	theme: theme
});

export const setExternalServices = (externalServices: ExternalServices): SetExternalServices => ({
	type: SessionActionTypes.SET_EXTERNAL_SERVICES,
	externalServices: externalServices
});

export const setFloors = (floors: string[]): SetFloors => ({
	type: SessionActionTypes.SET_FLOORS,
	floors: floors
});

// -------------------------------------------------

const SessionContext = createContext<ISessionContext>({
	state: initialState,
	dispatch: () => undefined
});

export const SessionContextProvider = ({ children }: ContextProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = { state, dispatch };

	return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

// Custom hook to throw a helpful error message if it's being called outside of SessionContextProvider
export const useSession = () => {
	const context = useContext(SessionContext);
	if (context === undefined) {
		throw new Error('useSession must be used within a SessionContextProvider');
	}
	return context;
};
