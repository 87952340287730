import { useBuildBrandTheme } from '../brands/useBrandTheme';
import { useSession } from '../stores/SessionStore';
import { useCheckQrCode } from './useCheckQrCode';
import { useExternalService } from './useExternalService';
import { useGetDashboardData } from './useGetDashboardData';
import { useGetLocationThemeAndLocale } from './useGetLocationThemeAndLocale';
import { useGetQrCode } from './useGetQrCode';

export function useLoadInitialAppData(isBuildingQr: boolean) {
	const { state } = useSession();

	const { loading: checkQrCodeLoading } = useCheckQrCode(state.code?.identifier, isBuildingQr);
	const { loading: getQrCodeLoading } = useGetQrCode(state.code?.identifier, state.codeExists);
	const { loading: getLocationThemeAndLocaleLoading } = useGetLocationThemeAndLocale(
		state.code?.identifier,
		state.codeExists,
		state.locationId
	);
	const { loading: getDashboardDataLoading } = useGetDashboardData(state.locationId, state.premiseId);
	const { loading: externalServiceLoading } = useExternalService(state.locationId, state.premiseId);
	useBuildBrandTheme();

	const allLoading = [
		checkQrCodeLoading,
		getQrCodeLoading,
		getLocationThemeAndLocaleLoading,
		getDashboardDataLoading,
		externalServiceLoading
	];

	const isLoading = allLoading.some(state => state);

	return { isLoading };
}
