import { LocalizedTextList } from '../locales/LocalizedText';

export interface TenantNotification {
	id: string;
	locationId: string;
	channels: TenantNotificationChannel[];
	title: LocalizedTextList;
	description?: LocalizedTextList;
	startDate: Date;
	endDate: Date;
	active: boolean;
	type: NotificationType;
}

export interface TenantNotificationChannel {
	channelType: ChannelType;
	channelIds: string[];
}

export enum ChannelType {
	FEELIS,
	INFOTV
}

export enum NotificationType {
	INFO = 'INFO',
	SUCCESS = 'SUCCESS'
}
