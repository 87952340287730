import { useEffect, useState } from 'react';
import { getLocalizedText } from '../../locales/LocalizedText';
import { TenantNotification } from '../../models/TenantNotification';
import { useSession } from '../../stores/SessionStore';
import { CarouselContainer } from '../styles/InfoTV.styles';
import { NotificationTile } from './NotificationTile';

interface Props {
	notifications: TenantNotification[];
}

export function NotificationCarousel({ notifications }: Props) {
	const { state } = useSession();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isSliding, setIsSliding] = useState(false);

	useEffect(() => {
		const currentNotification = notifications[currentIndex];
		const titleText = getLocalizedText(state.language, currentNotification.title);
		const descriptionText = getLocalizedText(state.language, currentNotification.description);

		const displayTime = calculateDisplayTime(titleText, descriptionText) * 1000;

		const timer = setTimeout(() => {
			setIsSliding(true);

			setTimeout(() => {
				setCurrentIndex(prevIndex => (prevIndex + 1) % notifications.length);
				setIsSliding(false);
			}, 500);
		}, displayTime);

		return () => clearTimeout(timer);
	}, [currentIndex, state.language, notifications]);

	if (!state.language) return null;
	return (
		<CarouselContainer>
			<NotificationTile
				notification={notifications[currentIndex]}
				currentLocale={state.language}
				isSliding={isSliding}
			/>
		</CarouselContainer>
	);
}

function calculateDisplayTime(title: string, description: string) {
	const totalChars = title.length + description.length;
	const readingSpeed = 10; // characters per second
	const minTime = 3;
	const maxTime = 20;
	return Math.min(maxTime, Math.max(minTime, totalChars / readingSpeed));
}
