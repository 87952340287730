import { useMutation } from '@apollo/client';
import { useState } from 'react';
import {
	AnsweredQuestionData,
	AnsweredQuestionsByPremise,
	QUESTION_STORAGE_KEY
} from '../feelis-portal/components/questionnaire/surveyUtils';
import { SEND_ANSWER } from '../graphql/mutations';
import { QrCode, Question, TempQrCode } from '../models/queries/QueryResponses';
import { isQrCode } from '../utils/typeGuards';

export function useSendAnswer(
	question: Question,
	qrcode: QrCode | TempQrCode | undefined,
	premiseId: string | undefined,
	questionsAnswered: AnsweredQuestionsByPremise
) {
	const [sendAnswer] = useMutation<{ answerSavedResponse: string }>(SEND_ANSWER);
	const [answerSent, setAnswerSent] = useState<boolean>(question.id in questionsAnswered);

	async function handleAnswerSent(value: number) {
		setAnswerSent(true);
		if (question && isQrCode(qrcode) && premiseId) {
			await sendAnswer({
				variables: {
					premiseId: premiseId,
					questionId: question.id,
					qrCodeId: qrcode.id,
					value: value,
					sessionId: window.sessionStorage.getItem('session')
				}
			});
			const stored = localStorage.getItem(QUESTION_STORAGE_KEY);
			let existingAnswers: AnsweredQuestionsByPremise = JSON.parse(stored ?? '{}');

			const updatedPremiseAnswers: Record<string, AnsweredQuestionData> = {
				...(existingAnswers[premiseId] ?? {}),
				[question.id]: {
					answer: value,
					timestamp: Date.now()
				}
			};

			const updatedAnswers: AnsweredQuestionsByPremise = {
				...existingAnswers,
				[premiseId]: updatedPremiseAnswers
			};

			localStorage.setItem(QUESTION_STORAGE_KEY, JSON.stringify(updatedAnswers));
		}
	}

	return { handleAnswerSent, answerSent };
}
