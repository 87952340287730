import { Divider } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useBrandColors } from '../../../brands/useBrandTheme';
import { FreesiButton } from '../../../components/buttons/FreesiButton';
import { useSendAnswer } from '../../../hooks/useSendAnswer';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { useWithFallbackContent } from '../../../hooks/useWithFallbackContent';
import { Question, QuestionOption } from '../../../models/queries/QueryResponses';
import { useSession } from '../../../stores/SessionStore';
import { TextButton } from '../../../styles-v1/buttons';
import { FlexRow } from '../../../styles-v1/containers';
import { QuestionColumn } from '../../../styles/styled-components/columns';
import { QuestionOptionText } from '../../../styles/styled-components/text';
import { iconChooser } from '../../../utils/iconFromValue';
import { OptionsContainer, QuestionText } from '../../styles/FeelisPortal.styles';
import { ProgressBar } from './ProgressBar';
import { storedAnswers } from './surveyUtils';

interface Props {
	currentQuestionIndex: number;
	currentQuestion: Question;
	onClose: () => void;
	totalQuestions: number;
	onAnswer?: (o: QuestionOption) => void;
	allQuestionsAnswered?: boolean;
	handleNextQuestion: () => void;
}

export function SurveyQuestion({
	currentQuestionIndex,
	currentQuestion,
	totalQuestions,
	onClose,
	onAnswer,
	allQuestionsAnswered,
	handleNextQuestion
}: Props) {
	const { t } = useTypedTranslation();
	const { state } = useSession();
	const colors = useBrandColors();
	const withFallback = useWithFallbackContent();
	const isTempQuestion = (targetId: string) => state.questionnaire?.tempQuestionId === targetId;

	const premiseAnswers = state.premiseId ? storedAnswers[state.premiseId] ?? {} : {};
	const previousAnswerValue = premiseAnswers[currentQuestion.id]?.answer;

	const previousAnswer = currentQuestion.options.find(option => option.value === previousAnswerValue);

	const { handleAnswerSent } = useSendAnswer(currentQuestion, state.code, state.premiseId, storedAnswers);

	function handleAnswer(option: QuestionOption) {
		onAnswer && onAnswer(option);
		handleAnswerSent(option.value);
	}

	return (
		<>
			<QuestionText>{withFallback(currentQuestion.title)}</QuestionText>
			<OptionsContainer>
				{currentQuestion.options.map(option => {
					const IconComponent = iconChooser(
						option.value,
						currentQuestion.options.length,
						isTempQuestion(currentQuestion.id)
					);

					const isSelected = previousAnswer?.value === option.value;

					return (
						<QuestionColumn key={option.value}>
							<IconWrapper
								isTempIcon={isTempQuestion(currentQuestion.id)}
								onClick={() => (!allQuestionsAnswered && !previousAnswer ? handleAnswer(option) : undefined)}>
								<IconComponent fill={isSelected ? colors.primary : '#A6B0C1'} />
							</IconWrapper>
							<QuestionOptionText isSelected={isSelected}>
								{option.label && withFallback(option.label)}
							</QuestionOptionText>
						</QuestionColumn>
					);
				})}
			</OptionsContainer>
			<ProgressBar currentIndex={currentQuestionIndex + 1} totalIndex={totalQuestions} />
			<Divider variant="middle" style={{ width: '90%' }} />
			<FlexRow style={{ justifyContent: 'center', alignSelf: 'flex-end', gap: '10px' }}>
				<TextButton customcolor={colors.button.secondary.fontColor} onClick={onClose}>
					{t('action.skip')}
				</TextButton>
				{allQuestionsAnswered && currentQuestionIndex + 1 < totalQuestions && (
					<FreesiButton secondary onClick={handleNextQuestion} label={t('action.next')} />
				)}
			</FlexRow>
		</>
	);
}

const IconWrapper = styled(motion.div)<{ isTempIcon: boolean }>`
	display: flex;
	height: ${props => (props.isTempIcon ? '6.5rem' : '5.5rem')};
	width: ${props => (props.isTempIcon ? '6.5rem' : '5.5rem')};
	cursor: pointer;
	transition: background 0.3s, color 0.3s;

	svg {
		width: 100%;
		height: 100%;
	}
`;
