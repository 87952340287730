import { isEmpty } from 'lodash';
import { tenantNotificationApiFactory } from '../api/tenantNotificationApi';
import { useSimpleApiQuery } from './useSimpleApiQuery';

export function useGetFeelisPortalNotifications(qrCodeId: string) {
	const {
		response: notifications,
		loading,
		error
	} = useSimpleApiQuery(
		tenantNotificationApiFactory,
		api => api.getTenantNotificationForFeelisPortal(qrCodeId),
		[qrCodeId],
		{
			shouldFetch: !isEmpty(qrCodeId)
		}
	);

	return {
		notifications,
		loading,
		error
	};
}
