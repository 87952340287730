import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { QuarterColumn } from '../../../styles/styled-components/columns';
import { DayCounterCircle } from '../../../styles/styled-components/misc';
import { QuarterRow } from '../../../styles/styled-components/rows';
import { BigTitle, ColumnTitle, DayCounterNumber } from '../../../styles/styled-components/text';
import { backgroundConfig } from '../../../utils/helperFunctions';
import { ScoreConfig } from './ScoreTile';

type DesktopFeedbackScoreProps = {
	config: ScoreConfig;
	data: number;
};

export function DesktopFeedbackScore({ config, data }: DesktopFeedbackScoreProps) {
	const { t, unsafeTranslation } = useTypedTranslation();

	return (
		<QuarterRow mobile={false} background={backgroundConfig(config.color)}>
			<QuarterColumn>
				<ColumnTitle mobile={false}>{unsafeTranslation(config.text)}</ColumnTitle>
			</QuarterColumn>
			<QuarterColumn centered>{data && <Counter dayCount={data} />}</QuarterColumn>
			<QuarterColumn centered>
				<BigTitle style={{ fontSize: '3rem', textAlign: 'center' }}>{t('user-satisfaction.no-data-info')}</BigTitle>
			</QuarterColumn>
		</QuarterRow>
	);
}

interface CounterProps {
	dayCount: number;
}

function Counter({ dayCount }: CounterProps) {
	return (
		<DayCounterCircle>
			<DayCounterNumber>{dayCount}</DayCounterNumber>
		</DayCounterCircle>
	);
}
