import styled from 'styled-components';
import { useBrandColors } from '../../brands/useBrandTheme';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { ScoreType } from '../../models/Feelis';
import { iconChooser } from '../../utils/iconFromValue';
import { scoreMapper } from '../../views/infotv/infoHelper';
import { IconWrapper } from '../styles/FeelisPortal.styles';

interface Props {
	value: number;
}

export function SatisfactionTile({ value }: Props) {
	const { unsafeTranslation } = useTypedTranslation();
	const colors = useBrandColors();
	const IconComponent = iconChooser(value);

	return (
		<>
			<IconWrapper height={5}>
				<IconComponent fill={colors.feelisPortal.darkFontColor} />
			</IconWrapper>
			<ValueText>{unsafeTranslation(scoreMapper(value, ScoreType.SATISFACTION))}</ValueText>
		</>
	);
}

const ValueText = styled.span`
	font-size: 2.2rem;
	font-weight: 500;
`;
