import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { SessionContextProvider } from '../src/stores/SessionStore';
import { ThemeProvider } from '@mui/material/styles';
import materialUITheme from './materialUiTheme';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
	uri: /Android/i.test(navigator.userAgent)
		? process.env.REACT_APP_API_BASE_URL_ANDROID
		: process.env.REACT_APP_API_BASE_URL,
	cache: new InMemoryCache()
});

ReactDOM.render(
	<ThemeProvider theme={materialUITheme}>
		<Suspense fallback={<div className="element is-loading"></div>}>
			<BrowserRouter>
				<ApolloProvider client={client}>
					<SessionContextProvider>
						<App />
					</SessionContextProvider>
				</ApolloProvider>
			</BrowserRouter>
		</Suspense>
	</ThemeProvider>,
	document.getElementById('root')
);
