import { Backdrop, Divider, Modal } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { FreesiButton } from '../../../components/buttons/FreesiButton';
import { TenantNotification } from '../../../models/TenantNotification';
import { FlexColumn } from '../../../styles-v1/containers';
import { ModalContainer } from '../../styles/FeelisPortal.styles';
import { NotificationHeader } from './NotificationHeader';
import { SingleNotification } from './SingleNotification';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';

interface Props {
	open: boolean;
	onClose: () => void;
	notifications: TenantNotification[];
}

export function NotificationCarousel({ open, onClose, notifications }: Props) {
	const { t } = useTypedTranslation();
	const [index, setIndex] = useState(0);

	function handleClose(event: React.SyntheticEvent, reason: string) {
		if (reason === 'backdropClick') return;
		handleSaveSeenNotifications();
	}

	function handleSaveSeenNotifications() {
		const seenNotifications = JSON.parse(localStorage.getItem('seenNotifications') || '[]');
		const updatedSeen = [...new Set([...seenNotifications, ...notifications.map(n => n.id)])];
		localStorage.setItem('seenNotifications', JSON.stringify(updatedSeen));
		onClose();
	}

	function handleNextNotification() {
		const nextIndex = index + 1;
		if (nextIndex < notifications.length) {
			setIndex(nextIndex);
		}
	}

	function handlePreviousNotification() {
		if (index > 0) {
			setIndex(index - 1);
		}
	}

	function handleClickNavigation(e: React.MouseEvent<HTMLDivElement>) {
		const { clientX, currentTarget } = e;
		const middle = currentTarget.clientWidth / 2;

		if (clientX < middle) {
			handlePreviousNotification();
		} else {
			handleNextNotification();
		}
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			BackdropComponent={Backdrop}
			BackdropProps={{ style: { backdropFilter: 'blur(5px)' } }}>
			<motion.div
				initial={{ opacity: 0, y: 50 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -50 }}
				transition={{ duration: 0.5 }}
				style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
				<ModalContainer>
					<NotificationHeader index={index} notification={notifications[index]} notifications={notifications} />
					<SingleNotification
						notification={notifications[index]}
						index={index}
						handleClickNavigation={handleClickNavigation}
					/>
					<Divider variant="middle" style={{ width: '90%' }} />
					<FlexColumn style={{ alignItems: 'flex-end', justifyContent: 'center', padding: '15px 20px' }}>
						<FreesiButton onClick={handleSaveSeenNotifications} secondary label={t('action.got-it')} />
					</FlexColumn>
				</ModalContainer>
			</motion.div>
		</Modal>
	);
}
