import { useBrandTheme } from '../../../brands/useBrandTheme';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ReactComponent as TemperatureIcon } from '../../../images/temp.svg';
import { FlexColumn } from '../../../styles-v1/containers';
import { IndicatorNameText, TemperatureText, Tile } from '../../styles/InfoTV.styles';

interface Props {
	temperature: number;
	floors?: string[];
}

export function TemperatureTile({ temperature, floors }: Props) {
	const { t } = useTypedTranslation();
	const { themeStyles } = useBrandTheme();

	return (
		<Tile
			backgroundColor={themeStyles.colors.indicators.temperature}
			fontColor={themeStyles.colors.infoTV.lightFontColor}>
			<TemperatureIcon style={{ height: '9em' }} fill={themeStyles.colors.infoTV.lightFontColor} />
			<TemperatureText fonts={themeStyles.typography}>{temperature.toFixed(1)} °C</TemperatureText>
			<FlexColumn style={{ gap: '10px', alignItems: 'flex-end' }}>
				<IndicatorNameText>{t('core.indoor-temp')}</IndicatorNameText>
				<span style={{ fontSize: '2.5rem' }}>
					{floors && !!floors.length ? t('core.floors-avg') : t('core.building-avg')}
				</span>
			</FlexColumn>
		</Tile>
	);
}
