import { FC, SVGProps } from 'react';
import { IconWrapper, IndicatorNameText, IndicatorValueText, Tile } from '../styles/FeelisPortal.styles';

interface Props {
	value: string;
	name: string;
	icon: FC<SVGProps<SVGSVGElement>>;
	backgroundColor: string;
	fontColor: string;
}

export function MeasurementTile({ value, name, icon: Icon, backgroundColor, fontColor }: Props) {
	return (
		<Tile backgroundColor={backgroundColor} fontColor={fontColor}>
			<IconWrapper height={3.5} alignSelf="flex-start">
				<Icon fill={fontColor} />
			</IconWrapper>
			<IndicatorValueText>{value}</IndicatorValueText>
			<IndicatorNameText>{name}</IndicatorNameText>
		</Tile>
	);
}
