import { useEffect, useState } from 'react';

const THIRTY_MINUTES = 1800000;

export function useAutoRefresh(interval: number = THIRTY_MINUTES) {
	const [refreshKey, setRefreshKey] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setRefreshKey(key => key + 1);
		}, interval);

		return () => clearInterval(intervalId);
	}, [interval]);

	return refreshKey;
}
