import { QUESTION_STORAGE_KEY } from '../../../feelis-portal/components/questionnaire/surveyUtils';
import { useSendAnswer } from '../../../hooks/useSendAnswer';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { useWithFallbackContent } from '../../../hooks/useWithFallbackContent';
import { Question, QuestionOption } from '../../../models/queries/QueryResponses';
import { useSession } from '../../../stores/SessionStore';
import { QuestionColumn } from '../../../styles/styled-components/columns';
import { QuestionContainer } from '../../../styles/styled-components/containers';
import { QuestionIcon } from '../../../styles/styled-components/icons';
import { CenteredRow } from '../../../styles/styled-components/rows';
import { IconLabel, QuestionLabel, QuestionOptionText, ThankYouText } from '../../../styles/styled-components/text';
import { iconChooser } from './iconChooser';

type QuestionProps = {
	background: string;
	question: Question;
};

export function QuestionTile({ background, question }: QuestionProps) {
	const { t } = useTypedTranslation();
	const { state } = useSession();
	const withFallback = useWithFallbackContent();
	const storedAnswers = JSON.parse(localStorage.getItem(QUESTION_STORAGE_KEY) || '{}');

	const { answerSent, handleAnswerSent } = useSendAnswer(question, state.code, state.premiseId, storedAnswers);

	const isTempQuestion = (targetId: string) => state.questionnaire?.tempQuestionId === targetId;

	return (
		<QuestionContainer background={background}>
			<div style={{ width: state.isDesktop ? '50%' : '100%' }}>
				<QuestionLabel>{question && withFallback(question.title)}</QuestionLabel>
				{!answerSent && question && question.options && (
					<CenteredRow>
						{question.options.map((p: QuestionOption, index) => {
							// kludge: find saner way to hide option 1 & 2's label
							const hideLabel = p.label.en === 'Somewhat displeased' || p.label.en === 'Somewhat pleased';
							return (
								<QuestionColumn key={p.value}>
									<IconLabel>
										<QuestionIcon
											src={iconChooser(p.value, question.options.length, isTempQuestion(question.id))}
											isTempIcon={isTempQuestion(question.id)}
											onClick={() => handleAnswerSent(p.value)}
										/>
									</IconLabel>

									{!hideLabel && <QuestionOptionText>{p.label && withFallback(p.label)}</QuestionOptionText>}
								</QuestionColumn>
							);
						})}
					</CenteredRow>
				)}
				{answerSent && <ThankYouText>{t('questions.thank-you')}</ThankYouText>}
			</div>
		</QuestionContainer>
	);
}
