import { SupportedTheme } from '../brands/brandUtilities';
import { basicGet } from './basicRestOperations';
import { feelisAxiosFactory } from './feelisAxiosFactory';

export function locationApiFactory() {
	const axios = feelisAxiosFactory('external');
	return {
		getLocationTheme: (locationId: string) => basicGet<SupportedTheme>(axios, `/location/${locationId}/theme`)
	};
}
