import { Box } from '@mui/material';
import styled from 'styled-components';
import { FlexColumn } from '../../styles-v1/containers';
import { motion } from 'framer-motion';

type TileProps = {
	backgroundColor: string;
	fontColor: string;
};

export const Tile = styled(FlexColumn)<TileProps>`
	background: ${props => props.backgroundColor};
	color: ${props => props.fontColor};
	border-radius: 5px;
	gap: 1.5rem;
	justify-content: space-between;
	min-height: fit-content;
	padding: 15px;
	box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.2);
	flex-grow: 1;
`;

export const IndicatorNameText = styled.span`
	font-size: 2rem;
	text-align: end;
	align-self: flex-end;
`;

export const SatisfactionNameText = styled.span`
	font-size: 2rem;
	text-align: center;
	word-break: break-word;
`;

export const SatisfactionSubText = styled.span`
	font-size: 1.5rem;
	text-align: center;
`;

export const SatisfactionCircle = styled('div')<{ color: string }>`
	width: 8rem;
	height: 8rem;
	border-radius: 50%;
	border: solid 2px ${props => props.color};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	font-weight: 500;
`;

export const IconWrapper = styled.div<{ height: number; alignSelf?: string }>`
	align-self: ${props => props.alignSelf};
	display: flex;
	height: ${props => props.height}rem;
	aspect-ratio: 1;

	svg {
		width: 100%;
		height: 100%;
	}
`;

export const IndicatorValueText = styled.span`
	font-size: 3rem;
	text-align: center;
	align-self: center;
	font-weight: 500;
`;

export const DashboardContainer = styled(FlexColumn)<{ backgroundColor: string }>`
	height: 100%;
	width: 100%;
	align-item: center;
	gap: 1rem;
	background-color: ${props => props.backgroundColor};
`;

export const Title = styled.span<{ font: string; fontColor: string }>`
	font-family: ${props => props.font};
	font-size: 2rem;
	color: ${props => props.fontColor};
`;

export const ModalContainer = styled(Box)`
	background: white;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	width: 90%;
	position: relative;
`;

export const ModalIconWrapper = styled.div<{ backgroundColor: string }>`
	position: absolute;
	top: -15px;
	left: 30px;
	border-radius: 50%;
	background: ${props => props.backgroundColor};
	height: 5rem;
	width: 5rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ModalHeader = styled.div<{ backgroundColor: string }>`
	height: 50px;
	width: 100%;
	background: ${props => props.backgroundColor};
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const QuestionText = styled.span`
	font-size: 16px;
	font-weight: 500;
	color: #00195f;
	text-align: center;
`;

export const OptionsContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 10px;
`;

export const ProgressContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const StyledProgressBar = styled.div<{ progress: number }>`
	width: 90%;
	height: 6px;
	background: #e0e0e0;
	border-radius: 5px;
	overflow: hidden;
	position: relative;

	&:after {
		content: '';
		width: ${({ progress }) => progress}%;
		height: 100%;
		background: #a0f9d9;
		position: absolute;
		left: 0;
		transition: width 0.5s ease-in-out;
	}
`;

export const StyledMotionModal = styled(motion.div)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
`;

export const SurveyContentWrapper = styled(FlexColumn)`
	padding: 15px;
	align-items: center;
	width: 100%;
	gap: 15px;
`;
