import { useEffect } from 'react';
import { PromptSkips, SURVEY_SKIP_TIME, SURVEY_SKIPPED_KEY } from '../components/questionnaire/surveyUtils';
import { cleanExpiredEntries } from './useClearExpiredEntries';

export function useClearExpiredSkipActions() {
	useEffect(() => {
		cleanExpiredEntries<PromptSkips>(SURVEY_SKIPPED_KEY, SURVEY_SKIP_TIME, value =>
			typeof value === 'number' ? value : null
		);
	}, []);
}
