import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

export default createTheme({
	palette: {
		primary: {
			main: '#00195F'
		},
		secondary: blue
	},
	typography: {
		fontFamily: 'Poppins'
	}
});
