import styled from 'styled-components';
import { IconProps, QuestionIconProps } from '../interfaces';

export const MobileIcon = styled('img')<IconProps>`
	height: ${props => props.height};
	filter: initial;
`;

export const tempIconStyle = {
	height: '10em',
	alignSelf: 'flex-start',
	margin: '0 0 2rem 5rem'
};

export const DefaultIcon = styled('img')`
	height: 12em;
	@media (min-width: 2560px) {
		height: 14em;
	}
	@media (max-width: 1920px) {
		height: 9em;
	}
	@media (max-width: 1500px) {
		height: 8em;
	}
	@media (max-height: 1024px) and (max-width: 1500px) {
		height: 7em;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		height: 5.5em;
	}
`;

export const ColumnIcon = styled('img')`
	height: 11em;
	filter: initial;
	@media (max-height: 1400px) and (max-width: 1500px) {
		height: 7em;
	}
	@media (max-height: 800px) {
		height: 9em;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		height: 5em;
	}
`;

export const QuestionIcon = styled('img')<QuestionIconProps>`
	height: ${props => (props.isTempIcon ? '6.5rem' : '5.5rem')};
	width: ${props => (props.isTempIcon ? '6.5rem' : '5.5rem')};
	filter: initial;
`;

export const SummaryIcon = styled('div')`
	display: flex;
	justify-content: flex-end;
	width: 40px;
	color: #050c42;
`;
