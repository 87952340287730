import MegaphoneIcon from '@mui/icons-material/Campaign';
import styled from 'styled-components';
import { useBrandColors } from '../../../brands/useBrandTheme';
import { getNotificationColor } from '../../../info-tv/components/NotificationTile';
import { TenantNotification } from '../../../models/TenantNotification';
import { ModalHeader, ModalIconWrapper } from '../../styles/FeelisPortal.styles';

interface Props {
	notification: TenantNotification;
	notifications: TenantNotification[];
	index: number;
}
export function NotificationHeader({ notification, notifications, index }: Props) {
	const { notification: notificationColors } = useBrandColors();
	const colors = getNotificationColor(notification, notificationColors);

	return (
		<>
			<ModalIconWrapper backgroundColor={colors.iconBackground}>
				<MegaphoneIcon
					style={{
						fontSize: '3.5rem',
						color: colors.iconColor
					}}
				/>
			</ModalIconWrapper>
			<ModalHeader backgroundColor={colors.backgroundColor}>
				{notifications.length > 1 && (
					<IndicatorContainer>
						{notifications.map((_, i) => (
							<IndicatorSegment key={i} active={i === index} />
						))}
					</IndicatorContainer>
				)}
			</ModalHeader>
		</>
	);
}

const IndicatorContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 8px;
	width: fix-content;
	margin-right: 20px;
`;

const IndicatorSegment = styled.div<{ active: boolean }>`
	height: 4px;
	width: 30px;
	background: ${({ active }) => (active ? '#00195f' : '#a0aec0')};
	transition: background 0.3s ease-in-out;
	border-radius: 2px;
`;
