import styled from 'styled-components';
import { MobileProps } from '../interfaces';

export const StyledHeader = styled('div')<MobileProps>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: #fff;
	width: 100%;
	align-items: center;
	padding: ${props => (props.mobile ? '0 10px' : '1rem 2rem')};
	box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.2);
`;

export const Logo = styled('img')<MobileProps>`
	height: 2.5rem;
	margin-left: 1rem;
`;
