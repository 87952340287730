import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useBrandColors } from '../../../brands/useBrandTheme';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { QuestionPercentage } from '../../../models/queries/QueryResponses';
import { CustomizedLabel, renderFeelisYLabel } from '../../../utils/helperFunctions';

type FeelisBarChartProps = {
	dataType: string;
	questionInfo: QuestionPercentage;
};

export function FeelisBarChart({ questionInfo, dataType }: FeelisBarChartProps) {
	const { unsafeTranslation } = useTypedTranslation();
	const colors = useBrandColors();

	if (!questionInfo.percentages) return null;
	const formatter = (value: number) => unsafeTranslation(`user-satisfaction.mostAnsweredValue-${dataType}.${value}`);

	return (
		<ResponsiveContainer width="100%" minHeight={'15rem'}>
			<BarChart data={questionInfo.percentages} margin={{ right: 15, left: -20 }}>
				<CartesianGrid stroke={colors.feelisPortal.chart.grid} vertical={false} />
				<XAxis
					dataKey="value"
					padding={{ left: -5, right: -5 }}
					style={{ fontSize: 10, fill: colors.primary }}
					tickFormatter={formatter}
					tickLine={false}
					axisLine={false}
				/>
				<YAxis domain={[0, 100]} tick={renderFeelisYLabel} tickLine={false} axisLine={false} />
				<Bar
					isAnimationActive={false}
					dataKey="percentage"
					fill={colors.feelisPortal.chart.bar}
					radius={[5, 5, 0, 0]}
					barSize={30}
					label={CustomizedLabel(questionInfo.percentages, colors.feelisPortal.darkFontColor)}>
					{questionInfo.percentages.map((entry, index) => {
						return <Cell key={`cell-${index}`} fill={colors.primary} />;
					})}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
}
