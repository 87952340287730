import { ReactComponent as VentilationIcon } from '../../../images/co2.svg';
import { ReactComponent as TemperatureIcon } from '../../../images/temp.svg';
import { TranslationKeys } from '../../../locales/TranslationKeys';
import { ScoreBarChart } from './ScoreBarChart';
import { TempLineChart } from './TempLineChart';

type ChartType = 'TEMP' | 'CO2';

export function useDataType(dataType: string) {
	const typeToComponentMap = {
		TEMP: {
			icon: TemperatureIcon,
			chart: TempLineChart,
			title: 'core.temperature' as TranslationKeys,
			backgroundColor: '#f5f6ff'
		},
		CO2: {
			icon: VentilationIcon,
			chart: ScoreBarChart,
			title: 'core.ventilation' as TranslationKeys,
			backgroundColor: '#f9f5fc'
		}
	};

	const isDataType = (type: string): type is ChartType => type === 'TEMP' || type === 'CO2';

	if (!isDataType(dataType)) throw new Error('Invalid data type');

	const IconComponent = typeToComponentMap[dataType].icon;
	const ChartComponent = typeToComponentMap[dataType].chart;
	const title = typeToComponentMap[dataType].title;
	const background = typeToComponentMap[dataType].backgroundColor;

	return { IconComponent, ChartComponent, title, background };
}
