import { useBrandColors } from '../../brands/useBrandTheme';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { AverageUserSatisfaction } from '../../models/queries/QueryResponses';
import { FlexColumn } from '../../styles-v1/containers';
import { SatisfactionNameText, SatisfactionSubText } from '../styles/FeelisPortal.styles';
import { FeedbackTile } from './FeedbackTile';
import { SatisfactionTile } from './SatisfactionTile';

interface Props {
	value: AverageUserSatisfaction;
	timeRange: string;
}

export function SatisfactionWrapper({ value, timeRange }: Props) {
	const { t } = useTypedTranslation();
	const colors = useBrandColors();

	return (
		<FlexColumn
			style={{
				flex: 1,
				backgroundColor: colors.indicators.satisfaction,
				gap: '3rem',
				padding: '15px',
				boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
				color: colors.feelisPortal.darkFontColor,
				alignItems: 'center',
				justifyContent: 'space-evenly'
			}}>
			<FlexColumn style={{ alignItems: 'center', gap: '1em' }}>
				<SatisfactionNameText>{t('user-satisfaction.root')}</SatisfactionNameText>
				<SatisfactionSubText>{timeRange}</SatisfactionSubText>
			</FlexColumn>
			<FlexColumn style={{ alignItems: 'center', gap: '2em' }}>
				{!!value.value && <SatisfactionTile value={value.value} />}
				{!!value.lastNegFeedback && <FeedbackTile lastNegFeedback={value.lastNegFeedback} />}
			</FlexColumn>
		</FlexColumn>
	);
}
