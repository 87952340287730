import { QuestionTile } from '../../components/tile/question/QuestionTile';
import { useWithFallbackContent } from '../../hooks/useWithFallbackContent';
import { useSession } from '../../stores/SessionStore';
import { QuestionnaireContainer } from '../../styles/styled-components/containers';
import { QuestionnaireTitle } from '../../styles/styled-components/text';

export function QuestionnaireView() {
	const { state } = useSession();
	const withFallback = useWithFallbackContent();

	return (
		<QuestionnaireContainer>
			<QuestionnaireTitle>{withFallback(state.questionnaire?.title)}</QuestionnaireTitle>
			{state.questionnaire?.questions.map((question, index) => {
				return <QuestionTile key={index} background={index % 2 === 0 ? '#DEFDF2' : '#f5f6ff'} question={question} />;
			})}
		</QuestionnaireContainer>
	);
}
