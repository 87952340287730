import { Fab } from '@mui/material';
import { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
	onClick: () => void;
	icon: ReactElement;
	backgroundColor: string;
}
export function FeedbackFAB({ onClick, icon, backgroundColor }: Props) {
	return (
		<FabButton onClick={onClick} backgroundColor={backgroundColor}>
			{icon}
		</FabButton>
	);
}

const FabButton = styled(Fab)<{ backgroundColor: string }>`
	position: fixed;
	bottom: 9rem;
	right: 20px;
	background-color: ${props => props.backgroundColor};
	color: white !important;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
`;
