import { tenantNotificationApiFactory } from '../../api/tenantNotificationApi';
import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import { useSimpleApiQuery } from '../../hooks/useSimpleApiQuery';

export function useGetInfoTVNotificationsV1(infoTVDefinitionId: string) {
	const refreshKey = useAutoRefresh();

	const {
		response: infoTVNotifications,
		loading,
		error
	} = useSimpleApiQuery(
		tenantNotificationApiFactory,
		api => api.getTenantNotificationsForInfoTV(infoTVDefinitionId),
		[infoTVDefinitionId, refreshKey],
		{
			shouldFetch: !!infoTVDefinitionId || infoTVDefinitionId !== ''
		}
	);

	return {
		infoTVNotifications,
		loading,
		error
	};
}
