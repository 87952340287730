import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { Backdrop, Modal } from '@mui/material';
import { useState } from 'react';
import { useBrandColors } from '../../../brands/useBrandTheme';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { Question } from '../../../models/queries/QueryResponses';
import {
	ModalContainer,
	ModalHeader,
	ModalIconWrapper,
	StyledMotionModal,
	SurveyContentWrapper
} from '../../styles/FeelisPortal.styles';
import { SurveyConfirm } from './SurveyConfirm';
import { SurveyQuestion } from './SurveyQuestion';

interface Props {
	open: boolean;
	handleClose: () => void;
	currentQuestion: number;
	questions: Question[];
	handleNextQuestion: () => void;
}

export function SurveyReview({ handleClose, open, currentQuestion, questions, handleNextQuestion }: Props) {
	const { t } = useTypedTranslation();
	const colors = useBrandColors();
	const [showContinuePrompt, setShowContinuePrompt] = useState(true);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			BackdropComponent={Backdrop}
			BackdropProps={{ style: { backdropFilter: 'blur(5px)' } }}>
			<StyledMotionModal
				initial={{ opacity: 0, y: 50 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -50 }}
				transition={{ duration: 0.5 }}>
				<ModalContainer>
					<ModalIconWrapper backgroundColor={colors.primary}>
						<ForumOutlinedIcon style={{ fontSize: '3rem', color: colors.secondary }} />
					</ModalIconWrapper>
					<ModalHeader backgroundColor={colors.indicators.satisfaction} />
					<SurveyContentWrapper>
						{showContinuePrompt ? (
							<SurveyConfirm
								question={t('user-satisfaction.survey-review')}
								handleContinue={() => setShowContinuePrompt(false)}
								handleSkip={handleClose}
							/>
						) : (
							<SurveyQuestion
								currentQuestionIndex={currentQuestion}
								currentQuestion={questions[currentQuestion]}
								onClose={handleClose}
								totalQuestions={questions.length}
								allQuestionsAnswered
								handleNextQuestion={handleNextQuestion}
							/>
						)}
					</SurveyContentWrapper>
				</ModalContainer>
			</StyledMotionModal>
		</Modal>
	);
}
