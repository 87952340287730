import { isEmpty, isNil, merge } from 'lodash';
import defaultFreesiLogo from './_default/primaryLogo.svg';
import secondaryFreesiLogo from './_default/secondaryLogo.svg';
import defaultFreesi from './_default/styles.json';
import { Theme, ThemeOverride } from './Theme';

export const defaultTheme: Theme = defaultFreesi;
defaultTheme.primaryLogo = defaultFreesiLogo;
defaultTheme.secondaryLogo = secondaryFreesiLogo;

export enum SupportedTheme {
	FREESI = 'freesi',
	SRV = 'srv'
}

export async function buildThemeStyles(themeKey?: SupportedTheme): Promise<Theme> {
	if (isNil(themeKey) || isEmpty(themeKey) || themeKey === SupportedTheme.FREESI) {
		return defaultTheme;
	}

	const supportedThemes = Object.values(SupportedTheme);
	if (!supportedThemes.includes(themeKey)) {
		console.error(`Unsupported theme ${themeKey}`);
		return defaultTheme;
	}

	const override: ThemeOverride = (await import(`./${themeKey}/styles.json`)).default;
	const primaryLogo: string = (await import(`./${themeKey}/primaryLogo.svg`)).default;
	const secondaryLogo: string = (await import(`./${themeKey}/secondaryLogo.svg`)).default;

	override.primaryLogo = primaryLogo;
	override.secondaryLogo = secondaryLogo;

	return merge(defaultTheme, override);
}
