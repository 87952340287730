import { NotificationType, TenantNotification } from '../models/TenantNotification';
import { FlexColumn, FlexRow } from '../styles-v1/containers';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import EventIcon from '@mui/icons-material/Event';
import { useTypedTranslation } from '../hooks/useTypedTranslation';
import { Title } from './styles/FeelisPortal.styles';
import { useBrandTheme } from '../brands/useBrandTheme';
import { useDateFormatter } from '../hooks/useDateFormatter';
import styled from 'styled-components';
import { Theme } from '../brands/Theme';
import { getLocalizedText } from '../locales/LocalizedText';
import { useState } from 'react';
import { sortBy } from 'lodash';

interface Props {
	notifications: TenantNotification[];
}

export function NotificationView({ notifications }: Props) {
	const { t, language } = useTypedTranslation();
	const { themeStyles } = useBrandTheme();
	const { formatTimestamp } = useDateFormatter(false);
	const [sortedNotifications, setSortedNotifications] = useState(() => sortBy(notifications, 'startDate').reverse());

	function toggleSortOrder() {
		setSortedNotifications(prev => [...prev].reverse());
	}

	return (
		<FlexColumn style={{ gap: '10px', padding: '10px' }}>
			<FlexRow style={{ justifyContent: 'space-between', alignItems: 'center' }}>
				<Title font={themeStyles.typography.fontSecondary} fontColor={themeStyles.colors.feelisPortal.darkFontColor}>
					{t('core.notifications')}
				</Title>
				<SwapVertIcon
					style={{ color: themeStyles.colors.feelisPortal.darkFontColor, fontSize: '25px' }}
					onClick={toggleSortOrder}
				/>
			</FlexRow>
			{sortedNotifications.map((notification, index) => (
				<NotificationBox key={index}>
					<FlexRow style={{ gap: '5px' }}>
						<IconWrapper backgroundColor={getNotificationColor(notification, themeStyles).backgroundColor}>
							<ChatBubbleOutlineIcon style={{ color: themeStyles.colors.primary, fontSize: '15px' }} />
						</IconWrapper>
						<span style={{ fontWeight: '500' }}>{getLocalizedText(language, notification.title)}</span>
					</FlexRow>
					{!!notification.description?.length && <span>{getLocalizedText(language, notification.description)}</span>}
					<FlexRow style={{ alignItems: 'center', gap: '5px' }}>
						<IconWrapper backgroundColor={getNotificationColor(notification, themeStyles).backgroundColor}>
							<EventIcon style={{ color: themeStyles.colors.primary, fontSize: '15px' }} />
						</IconWrapper>
						<span>
							{formatTimestamp(notification.startDate)} - {formatTimestamp(notification.endDate)}
						</span>
					</FlexRow>
				</NotificationBox>
			))}
		</FlexColumn>
	);
}

function getNotificationColor(notification: TenantNotification, themeStyles: Theme) {
	return notification.type === NotificationType.SUCCESS
		? themeStyles.colors.notification.success
		: themeStyles.colors.notification.info;
}

const NotificationBox = styled(FlexColumn)`
	border: 1px solid #d1dbeb;
	padding: 15px;
	gap: 10px;
	border-radius: 5px;
	font-size: 1.8rem;
`;

const IconWrapper = styled(FlexColumn)<{ backgroundColor: string }>`
	height: 25px;
	width: 25px;
	padding: 5px;
	justify-content: center;
	align-items: center;
	border-radius: 2px;
	background-color: ${props => props.backgroundColor};
`;
