import { useEffect } from 'react';
import {
	AnsweredQuestionsByPremise,
	QUESTION_STORAGE_KEY,
	SURVEY_SKIP_TIME
} from '../components/questionnaire/surveyUtils';
import { cleanExpiredEntries } from './useClearExpiredEntries';

export function useClearExpiredAnswers() {
	useEffect(() => {
		cleanExpiredEntries<AnsweredQuestionsByPremise>(QUESTION_STORAGE_KEY, SURVEY_SKIP_TIME, premiseAnswers => {
			const firstAnswer = Object.values(premiseAnswers)[0];
			return typeof firstAnswer?.timestamp === 'number' ? firstAnswer.timestamp : null;
		});
	}, []);
}
