import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ReactComponent as TemperatureIcon } from '../../../images/temp.svg';
import { useSession } from '../../../stores/SessionStore';
import { freesiColors } from '../../../styles/globalStyles';
import { MobileTemperatureContainer } from '../../../styles/styled-components/containers';
import { FlexRow } from '../../../styles/styled-components/rows';
import { MobileBlockLabel, MobileMeasurementValue } from '../../../styles/styled-components/text';

interface TileProps {
	data: number;
	hasBorder?: boolean;
}

export function MobileTempTile({ data, hasBorder }: TileProps) {
	const { t } = useTypedTranslation();
	const { state } = useSession();

	return (
		<MobileTemperatureContainer style={{ borderRadius: hasBorder ? '0.5rem' : 0 }}>
			<FlexRow
				style={{
					alignItems: 'start',
					height: '15%'
				}}>
				<TemperatureIcon fill={freesiColors.iisyPurple} height="3.5rem" />
			</FlexRow>
			<FlexRow style={{ justifyContent: 'center', alignItems: 'center', height: '70%' }}>
				<MobileMeasurementValue type={'measurementTemp'} isDesktop={state.isDesktop}>
					{`${data.toFixed(1)}°C`}
				</MobileMeasurementValue>
			</FlexRow>
			<MobileBlockLabel style={{ color: freesiColors.iisyGreen }}>{t('core.indoor-temp')}</MobileBlockLabel>
		</MobileTemperatureContainer>
	);
}
