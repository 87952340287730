import { isEmpty } from 'lodash';
import { useBrandColors } from '../../../brands/useBrandTheme';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { MeasurementData, QuestionPercentage } from '../../../models/queries/QueryResponses';
import { useSession } from '../../../stores/SessionStore';
import {
	GraphContainer,
	GraphSatisfactionContainer,
	LastWeekContainer
} from '../../../styles/styled-components/containers';
import { GraphTileInfoBox } from '../../../styles/styled-components/misc';
import { GraphTileInfoRow } from '../../../styles/styled-components/rows';
import { GraphTileMeasurementTitle, Heading3, Heading4, Paragraph } from '../../../styles/styled-components/text';
import { FeedbackTile } from '../satisfaction/FeedbackTile';
import { FeelisBarChart } from './FeelisBarChart';
import { useDataType } from './useDataType';

interface Props {
	data: MeasurementData;
	questionInfo: QuestionPercentage;
}

export function GraphTile({ data, questionInfo }: Props) {
	const { t } = useTypedTranslation();
	const { state } = useSession();
	const colors = useBrandColors();

	const { IconComponent, ChartComponent, title, background } = useDataType(data.type);

	return (
		<GraphContainer backgroundColor={background}>
			<GraphTileInfoRow>
				{data.currentValue && (
					<GraphTileInfoBox>
						<Heading3>
							{data.currentValue} <Heading3>{data.unit}</Heading3>
						</Heading3>
						<Paragraph>{t('graph.last-measurement')}</Paragraph>
					</GraphTileInfoBox>
				)}
				<GraphTileMeasurementTitle>{t(title)}</GraphTileMeasurementTitle>
				<IconComponent fill={colors.primary} height="3rem" />
			</GraphTileInfoRow>
			{data.measurements && !isEmpty(data.measurements) && (
				<LastWeekContainer>
					<Heading4>{t('core.last-week')}</Heading4>
					<ChartComponent data={data} isDesktop={state.isDesktop} />
				</LastWeekContainer>
			)}
			<GraphSatisfactionContainer>
				<Heading4>{t('user-satisfaction.root')}</Heading4>
				{questionInfo && questionInfo.percentages && (
					<FeelisBarChart questionInfo={questionInfo} dataType={data.type} />
				)}
				{questionInfo && typeof questionInfo.lastNegFeedback === 'number' && (
					<FeedbackTile isDesktop={state.isDesktop} lastNegFeedback={questionInfo.lastNegFeedback}></FeedbackTile>
				)}
			</GraphSatisfactionContainer>
			{/* TODO: reconsider if we still want this */}
			{/* {question && <QuestionTile question={question} background="f5f6ff" />} */}
		</GraphContainer>
	);
}
