import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { HeaderLogo } from './HeaderLogo';
import { HeaderColumn } from '../../styles/styled-components/columns';
import { StyledHeader } from '../../styles/styled-components/header';
import { HeaderRow } from '../../styles/styled-components/rows';
import { IndoorAirTitle, LocationTitle } from '../../styles/styled-components/text';
import { LanguagePicker } from '../language-picker/LanguagePicker';

type HeaderProps = {
	isDesktop: boolean;
	locationName: string | undefined;
	premiseName?: string | undefined;
	showLanguagePicker: boolean;
};

export function Header({ isDesktop, locationName, premiseName, showLanguagePicker }: HeaderProps) {
	const { t } = useTypedTranslation();

	return (
		<StyledHeader mobile={!isDesktop}>
			<HeaderLogo width="10rem" />
			<HeaderRow>
				<HeaderColumn>
					<LocationTitle mobile={!isDesktop}>{locationName}</LocationTitle>
					<IndoorAirTitle mobile>{premiseName ?? t('core.indoor-air')}</IndoorAirTitle>
				</HeaderColumn>
				{showLanguagePicker && <LanguagePicker />}
			</HeaderRow>
		</StyledHeader>
	);
}
