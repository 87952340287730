import { useEffect } from 'react';
import { infoTVApiFactory } from '../../api/infoTVApi';
import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import { useSimpleApiQuery } from '../../hooks/useSimpleApiQuery';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { setLanguage, setLocation, useSession } from '../../stores/SessionStore';
import { isEmpty } from 'lodash';

export function useGetInfoTVDefinition(infoTVDefinitionId: string) {
	const refreshKey = useAutoRefresh();
	const { i18n } = useTypedTranslation();
	const { dispatch } = useSession();

	const {
		response: infoTV,
		loading,
		error
	} = useSimpleApiQuery(infoTVApiFactory, api => api.getInfoTV(infoTVDefinitionId), [infoTVDefinitionId, refreshKey], {
		shouldFetch: !isEmpty(infoTVDefinitionId)
	});

	useEffect(() => {
		if (infoTV) {
			dispatch(setLocation(infoTV.locationId));
			dispatch(setLanguage(infoTV.language.toLowerCase()));
			i18n.changeLanguage(infoTV.language.toLowerCase());
		}
	}, [infoTV, dispatch, i18n]);

	return {
		infoTV,
		loading,
		error
	};
}
