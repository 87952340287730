import { Divider } from '@mui/material';
import { useBrandColors } from '../../../brands/useBrandTheme';
import { FreesiButton } from '../../../components/buttons/FreesiButton';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { TranslationKeys } from '../../../locales/TranslationKeys';
import { TextButton } from '../../../styles-v1/buttons';
import { OptionsContainer, QuestionText } from '../../styles/FeelisPortal.styles';
import { ProgressBar } from './ProgressBar';

interface Props {
	question: string;
	numOfQuestions?: number;
	handleContinue: () => void;
	handleSkip: () => void;
	skipButtonTitle?: TranslationKeys;
}

export function SurveyConfirm({
	question,
	numOfQuestions,
	handleContinue,
	handleSkip,
	skipButtonTitle = 'action.skip'
}: Props) {
	const { t } = useTypedTranslation();
	const colors = useBrandColors();

	return (
		<>
			<QuestionText>{question}</QuestionText>
			{numOfQuestions && <ProgressBar currentIndex={1} totalIndex={numOfQuestions} />}
			<Divider variant="middle" style={{ width: '90%' }} />
			<OptionsContainer>
				<TextButton customcolor={colors.button.secondary.fontColor} onClick={handleSkip}>
					{t(`${skipButtonTitle}`)}
				</TextButton>
				<FreesiButton label={t('action.continue')} onClick={handleContinue} />
			</OptionsContainer>
		</>
	);
}
