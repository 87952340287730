import happy from '../../../images/bear-happy.svg';
import neutral from '../../../images/bear-neutral.svg';
import sad from '../../../images/bear-sad.svg';
import vhappy from '../../../images/bear-vhappy.svg';
import vsad from '../../../images/bear-vsad.svg';
import cold from '../../../images/cold.svg';
import freezing from '../../../images/freezing1.svg';
import hot from '../../../images/hot1.svg';
import perfect from '../../../images/perfect.svg';
import warm from '../../../images/warm.svg';

export function iconChooser(optionValue: number, numberOfOptions?: number, isTempQuestion?: boolean) {
	const iconSet = isTempQuestion ? tempIconSet : getQuestionIconSet(numberOfOptions);

	if (optionValue >= 0 && optionValue <= 4) {
		return iconSet[optionValue];
	}
	throw new Error(`Invalid icon value for user satisfaction: ${optionValue}`);
}

const tempIconSet = [freezing, cold, perfect, warm, hot];

function getQuestionIconSet(numberOfOptions: number | undefined) {
	if (numberOfOptions === 3) {
		// when question has 3 options, min-max icon is the same sad icon
		return [sad, neutral, sad];
	} else if (numberOfOptions === 4) {
		// when question has 4 options, skip the second icon
		return [vsad, neutral, happy, vhappy];
	} else {
		return [vsad, sad, neutral, happy, vhappy];
	}
}
