import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { getSupportedLocaleOrDefault, useTypedTranslation } from '../../hooks/useTypedTranslation';
import { supportedLocales } from '../../i18n/getLocaleStrings';
import { useBrandColors } from '../../brands/useBrandTheme';

export function LanguagePicker() {
	const { i18n, language } = useTypedTranslation();
	const colors = useBrandColors();

	const [selection, setSelection] = useState<string>(language);

	function changeLanguage(lang: string) {
		i18n.changeLanguage(lang, (err, t) => {
			if (err) return console.error('Could not change language', err);
		});
	}

	function handleChange(event: SelectChangeEvent<string>) {
		const locale = getSupportedLocaleOrDefault(event.target.value?.toLowerCase());
		setSelection(locale);
		changeLanguage(locale.toLowerCase());
	}
	return (
		<Select
			variant="standard"
			className="header__language-picker"
			value={selection}
			onChange={handleChange}
			MenuProps={{
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'left'
				},
				transformOrigin: {
					vertical: 'top',
					horizontal: 'left'
				},
				PaperProps: { sx: { maxHeight: 20 } }
			}}
			sx={{
				fontSize: '2rem',
				fontWeight: 550,
				width: '6rem',
				color: colors.primary,
				'& .MuiSelect-icon': {
					color: colors.primary,
					top: '0.15rem'
				},
				'& .MuiSvgIcon-root': {
					width: '3rem',
					height: '3rem'
				}
			}}
			style={{ marginRight: '1rem' }}>
			{supportedLocales.map(l => (
				<MenuItem value={l} key={l} style={{ fontSize: '1.5rem' }}>
					{l.toUpperCase()}
				</MenuItem>
			))}
		</Select>
	);
}
