import useLocale from './useLocale';
import { format } from 'date-fns';

export function useDateFormatter(includeTime: boolean = false, missing = '-') {
	const { dateTimeFormat, dateFormat } = useLocale();
	const dFormat = includeTime ? dateTimeFormat : dateFormat;
	const formatDate = (d: Date) => format(d, dFormat);

	return {
		formatDate,
		formatTimestamp(timestamp?: string | number | Date, withUTC?: boolean) {
			if (!timestamp) return missing;
			try {
				if (withUTC) return formatDate(new Date(timestamp + 'Z'));
				return formatDate(new Date(timestamp));
			} catch (e) {
				return missing;
			}
		}
	};
}
