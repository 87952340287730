export function cleanExpiredEntries<T extends Record<string, any>>(
	storageKey: string,
	thresholdMs: number,
	entryTimestampGetter: (value: T[keyof T]) => number | null
) {
	const stored = localStorage.getItem(storageKey);
	if (!stored) return;

	let parsed: T;
	try {
		parsed = JSON.parse(stored);
	} catch {
		return;
	}

	const now = Date.now();
	const cleaned: Partial<T> = {};

	for (const [key, value] of Object.entries(parsed)) {
		const timestamp = entryTimestampGetter(value);
		if (timestamp !== null && now - timestamp <= thresholdMs) {
			cleaned[key as keyof T] = value;
		}
	}

	if (Object.keys(cleaned).length > 0) {
		localStorage.setItem(storageKey, JSON.stringify(cleaned));
	} else {
		localStorage.removeItem(storageKey);
	}
}
