import { FC, SVGProps } from 'react';
import { useBrandTheme } from '../../../brands/useBrandTheme';
import { FlexColumn } from '../../../styles-v1/containers';
import { IndicatorNameText, IndicatorValueText, Tile, IconWrapper } from '../../styles/InfoTV.styles';

interface Props {
	value: string;
	name: string;
	icon: FC<SVGProps<SVGSVGElement>>;
	backgroundColor: string;
	small?: boolean;
}

export function IndicatorTile({ value, name, icon: Icon, backgroundColor, small }: Props) {
	const { themeStyles } = useBrandTheme();

	return (
		<Tile backgroundColor={backgroundColor} fontColor={themeStyles.colors.infoTV.darkFontColor}>
			<IconWrapper color={themeStyles.colors.infoTV.darkFontColor}>
				<Icon fill={themeStyles.colors.infoTV.darkFontColor} />
			</IconWrapper>
			<FlexColumn style={{ gap: '10px' }}>
				<IndicatorValueText fonts={themeStyles.typography} small={small}>
					{value}
				</IndicatorValueText>
				<IndicatorNameText>{name}</IndicatorNameText>
			</FlexColumn>
		</Tile>
	);
}
