import { useEffect } from 'react';
import { useGetLocationTheme } from '../hooks/useGetLocationTheme';
import { setTheme, useSession } from '../stores/SessionStore';
import { buildThemeStyles, defaultTheme } from './brandUtilities';

export function useBuildBrandTheme() {
	const { state, dispatch } = useSession();
	const { theme } = useGetLocationTheme(state.locationId ?? null);

	useEffect(() => {
		async function loadTheme() {
			if (theme) {
				const styles = await buildThemeStyles(theme);
				dispatch(setTheme(styles));
			}
		}
		loadTheme();
	}, [theme, dispatch]);
}

export function useBrandTheme() {
	const { state } = useSession();
	return { themeStyles: state.theme ?? defaultTheme };
}
export function useBrandColors() {
	return useBrandTheme().themeStyles.colors;
}
