import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationType } from '../models/context/Session';
import { setCode, setFloors, setLanguage, setLocation, setType, useSession } from '../stores/SessionStore';
import { useTypedTranslation } from './useTypedTranslation';

const useQuery = function () {
	return new URLSearchParams(useLocation().search);
};

export function useGetInfoFromPath() {
	const { dispatch } = useSession();
	const { i18n } = useTypedTranslation();
	const query = useQuery();
	const { push } = useHistory();

	const isBuildingQr = Boolean(query.get('bid'));
	const identifier = isBuildingQr ? query.get('bid') : query.get('id');
	const locationId = query.get('lid');
	const lang = query.get('lang');
	const typeParam = query.get('type');
	const floors = query.get('floors');

	useEffect(() => {
		if (!locationId) {
			return;
		}

		if (!lang && !typeParam && !floors) {
			push(`/infotv?lid=${locationId}&lang=fi&type=default`);
		} else if (lang && typeParam) {
			dispatch(setLocation(locationId));
			dispatch(setLanguage(lang));
			i18n.changeLanguage(lang);
			dispatch(setType(typeParam === 'default' ? LocationType.DEFAULT : LocationType.RESET));

			if (floors) {
				dispatch(setFloors(floors.split(',')));
			}
		}
	}, [dispatch, i18n, locationId, lang, typeParam, floors, push]);

	useEffect(() => {
		if (identifier) {
			dispatch(setCode({ id: '', identifier }));
			window.localStorage.setItem('identifier', identifier);
		}
	}, [dispatch, identifier]);

	return { isBuildingQr };
}
