import { TenantNotification } from '../models/TenantNotification';
import { basicGet } from './basicRestOperations';
import { feelisAxiosFactory } from './feelisAxiosFactory';

export function tenantNotificationApiFactory() {
	const axios = feelisAxiosFactory('external');
	return {
		getTenantNotificationsForInfoTV: (definitionId: string) =>
			basicGet<TenantNotification[]>(axios, '/tenantnotifications/infoTV', { definitionId }),
		getTenantNotificationForFeelisPortal: (qrCodeId: string) =>
			basicGet<TenantNotification[]>(axios, '/tenantnotifications/feelis', { qrCodeId })
	};
}
