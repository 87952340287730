import { useEffect, useMemo, useState } from 'react';
import { useGetMeasurement } from './hooks/useGetMeasurement';
import { useGetQuestionnaire } from './hooks/useGetQuestionnaire';

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import { useBrandColors } from './brands/useBrandTheme';
import { BottomNavBarV1 } from './components/bottom-navbar/BottomNavBarV1';
import { Header } from './components/header/Header';
import { FeedbackFAB } from './feelis-portal/components/FeedbackFAB';
import { NotificationCarousel } from './feelis-portal/components/notification/NotificationCarousel';
import SurveyModal from './feelis-portal/components/questionnaire/SurveyModal';
import { SurveyReview } from './feelis-portal/components/questionnaire/SurveyReview';
import {
	shouldShowQuestion,
	storedAnswers,
	wasPromptRecentlySkipped
} from './feelis-portal/components/questionnaire/surveyUtils';
import { DashboardViewV1 } from './feelis-portal/DashboardViewV1';
import { useClearExpiredAnswers } from './feelis-portal/hooks/useClearExpiredAnswers';
import { useClearExpiredSkipActions } from './feelis-portal/hooks/useClearExpiredSkipActions';
import { useGetUnseenNotifications } from './feelis-portal/hooks/useGetUnseenNotifications';
import { NotificationView } from './feelis-portal/NotificationView';
import { useGetFeelisPortalNotifications } from './hooks/useGetFeelisPortalNotifications';
import { useIsDesktop } from './hooks/useIsDesktop';
import { ActionView } from './models/enum/ActionView';
import { isServiceRequestEnabled } from './models/ServiceRequestType';
import { setCodeExists, setIsDesktop, useSession } from './stores/SessionStore';
import { ApplicationWrapperContainer } from './styles/styled-components/containers';
import { MeasurementsView } from './views/measurements/MeasurementsView';
import { QuestionnaireView } from './views/questionnaire/QuestionnaireView';
import { ServiceRequestView } from './views/serviceRequest/ServiceRequestView';

export function ApplicationWrapper() {
	const { state, dispatch } = useSession();
	const colors = useBrandColors();
	const isDesktop = useIsDesktop();
	const { notifications, loading, error } = useGetFeelisPortalNotifications(state.code?.id ?? '');
	useGetQuestionnaire();
	useGetMeasurement(state.premiseId);
	useClearExpiredAnswers();
	useClearExpiredSkipActions();

	// Why?
	useEffect(() => {
		dispatch(setCodeExists(undefined));
		dispatch(setIsDesktop(isDesktop));
	}, [dispatch, isDesktop]);

	const [activeView, setActiveView] = useState<ActionView>(ActionView.Dashboard);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [isSurveyOpen, setSurveyOpen] = useState(false);
	const [isSurveyReview, setSurveyReview] = useState(false);
	const [isNotificationOpen, setNotificationOpen] = useState(false);
	const { unseenNotifications } = useGetUnseenNotifications(notifications);

	useEffect(() => {
		if (loading || (!unseenNotifications && !error)) return;

		if (!!unseenNotifications?.length) {
			setNotificationOpen(true);
			setSurveyOpen(false);
		} else {
			if (!wasPromptRecentlySkipped(state.premiseId)) {
				setSurveyOpen(true);
			}
			setNotificationOpen(false);
		}
	}, [unseenNotifications, setSurveyOpen, setNotificationOpen, loading, error, state.premiseId]);

	function handleCloseNotification() {
		setNotificationOpen(false);
		const store = wasPromptRecentlySkipped(state.premiseId);
		if (!wasPromptRecentlySkipped(state.premiseId)) {
			console.log({ store });
			setSurveyOpen(true);
		}
	}

	const unansweredQuestions = useMemo(() => {
		if (!state.premiseId || !state.questionnaire?.questions) return [];

		return state.questionnaire.questions.filter(q => shouldShowQuestion(state.premiseId!, q.id));
	}, [state.premiseId, state.questionnaire?.questions]);

	const allQuestionsAnswered = useMemo(() => {
		if (!state.premiseId || !state.questionnaire?.questions) return false;

		const premiseAnswers = storedAnswers[state.premiseId] ?? {};

		return state.questionnaire.questions.every(question => premiseAnswers[question.id]?.answer !== undefined);
	}, [state.premiseId, state.questionnaire?.questions]);

	return (
		<>
			<Header
				isDesktop={isDesktop}
				locationName={state.dashboard?.locationName}
				premiseName={state.dashboard?.premiseName}
				showLanguagePicker={true}
			/>
			<ApplicationWrapperContainer>
				{activeView === ActionView.Dashboard && <DashboardViewV1 />}
				{/* Here we should conditionally render ServiceRequestView or MeasurementsView on tab index 1 depending if the location has the granlund sync on */}
				{activeView === ActionView.Measurements && <MeasurementsView />}
				{activeView === ActionView.Questionnaire && <QuestionnaireView />}
				{activeView === ActionView.Servicerequest && <ServiceRequestView services={state.externalServices} />}
				{activeView === ActionView.Notification && <NotificationView notifications={notifications ?? []} />}
			</ApplicationWrapperContainer>
			<BottomNavBarV1
				activeView={activeView}
				setActiveView={setActiveView}
				isServiceRequestEnabled={isServiceRequestEnabled(state.externalServices)}
				hasNotifications={!!notifications?.length}
			/>
			{allQuestionsAnswered ? (
				<FeedbackFAB
					onClick={() => {
						setSurveyReview(true);
						setCurrentQuestion(0);
					}}
					icon={<MarkChatReadOutlinedIcon style={{ fontSize: '3rem', color: colors.primary }} />}
					backgroundColor={colors.secondary}
				/>
			) : (
				<FeedbackFAB
					onClick={() => setSurveyOpen(true)}
					icon={<ChatBubbleOutlineIcon style={{ fontSize: '3.5rem', color: colors.secondary }} />}
					backgroundColor={colors.primary}
				/>
			)}
			{!!unseenNotifications?.length && isNotificationOpen && (
				<NotificationCarousel
					open={isNotificationOpen}
					onClose={handleCloseNotification}
					notifications={unseenNotifications}
				/>
			)}
			{isSurveyOpen && !!unansweredQuestions.length && (
				<SurveyModal
					open={isSurveyOpen}
					onClose={() => setSurveyOpen(false)}
					questions={unansweredQuestions}
					currentQuestion={currentQuestion}
					setCurrentQuestion={setCurrentQuestion}
				/>
			)}
			{isSurveyReview && !!state.questionnaire?.questions && (
				<SurveyReview
					open={isSurveyReview}
					handleClose={() => setSurveyReview(false)}
					questions={state.questionnaire?.questions}
					currentQuestion={currentQuestion}
					handleNextQuestion={() => setCurrentQuestion(currentQuestion + 1)}
				/>
			)}
		</>
	);
}
