export const SURVEY_SKIP_TIME = 5 * 60 * 60 * 1000; // 5 hours
export const QUESTION_STORAGE_KEY = 'answered_questions_by_premise';
export const SURVEY_SKIPPED_KEY = 'survey_skipped_at';

export type AnsweredQuestionData = {
	answer: number;
	timestamp: number;
};

export type AnsweredQuestionsByPremise = Record<
	string, // premiseId
	Record<string, AnsweredQuestionData> // questionId -> AnsweredQuestionData
>;

export type PromptSkips = Record<string, number>; // premiseId → timestamp

export function shouldShowQuestion(premiseId: string, questionId: string): boolean {
	const storedData = JSON.parse(localStorage.getItem(QUESTION_STORAGE_KEY) || '{}') as AnsweredQuestionsByPremise;
	const answeredData = storedData?.[premiseId]?.[questionId];
	if (!answeredData || typeof answeredData.timestamp !== 'number') {
		return true;
	}
	return Date.now() - answeredData.timestamp > SURVEY_SKIP_TIME;
}

export function wasPromptRecentlySkipped(premiseId?: string): boolean {
	if (!premiseId) return false;

	const stored = localStorage.getItem(SURVEY_SKIPPED_KEY);
	if (!stored) return false;

	try {
		const data = JSON.parse(stored);
		const skipTimestamp = data[premiseId];
		return skipTimestamp && Date.now() - skipTimestamp < SURVEY_SKIP_TIME;
	} catch {
		return false;
	}
}

export function savePromptSkipTime(premiseId?: string) {
	if (!premiseId) return;

	const stored = localStorage.getItem(SURVEY_SKIPPED_KEY);
	let data: PromptSkips = {};

	try {
		data = stored ? JSON.parse(stored) : {};
	} catch {
		data = {};
	}

	data[premiseId] = Date.now();
	localStorage.setItem(SURVEY_SKIPPED_KEY, JSON.stringify(data));
}

export const storedAnswers = JSON.parse(
	localStorage.getItem(QUESTION_STORAGE_KEY) || '{}'
) as AnsweredQuestionsByPremise;
