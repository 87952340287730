import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ProgressContainer, StyledProgressBar } from '../../styles/FeelisPortal.styles';

interface Props {
	currentIndex: number;
	totalIndex: number;
}
export function ProgressBar({ currentIndex, totalIndex }: Props) {
	const { t } = useTypedTranslation();

	return (
		<ProgressContainer>
			<span style={{ color: '#D1D1D7', fontSize: '1.5rem' }}>
				{currentIndex} {t('core.of')} {totalIndex}
			</span>
			<StyledProgressBar progress={(currentIndex / totalIndex) * 100} />
		</ProgressContainer>
	);
}
