import { AppRouter } from './AppRouter';
import { useBrandTheme } from './brands/useBrandTheme';
import { useGetInfoFromPath } from './hooks/useGetInfoFromPath';
import { GlobalStyle } from './styles/globalStyles';

const App = () => {
	const { isBuildingQr } = useGetInfoFromPath();
	const { themeStyles } = useBrandTheme();

	return (
		<>
			<GlobalStyle fontFamily={themeStyles.typography.fontPrimary} fontColor={themeStyles.colors.primary} />
			<AppRouter isBuildingQr={isBuildingQr} />
		</>
	);
};

export default App;
