import styled from 'styled-components';
import {
	bodyText__Mobile,
	ellipsisText,
	freesiColors,
	headingText__Mobile,
	secondaryText__Mobile
} from '../globalStyles';
import { DataProps, MeasurementValueProps, MobileProps } from '../interfaces';

/* <--- TEXT COMPONENTS */
export const Paragraph = styled('span')`
	${secondaryText__Mobile}
	color: #00195A;
`;

export const Heading3 = styled('span')`
	${headingText__Mobile}
`;

export const Heading4 = styled('span')`
	${bodyText__Mobile}
	text-align: center;
`;

export const LocationTitle = styled('div')<MobileProps>`
	${ellipsisText}
	${props => (props.mobile ? bodyText__Mobile : headingText__Mobile)}
	color: #00195f;
	text-align: end;
	width: 100%;
	padding-top: ${props => (props.mobile ? '1rem' : 'auto')};
`;

export const IndoorAirTitle = styled('div')<MobileProps>`
	${ellipsisText}
	${bodyText__Mobile}
	width: 100%;
	color: #00195f;
	text-align: end;
`;

export const ColumnTitle = styled('h3')<MobileProps>`
	margin: 3rem;
	color: #00195f;
	font-size: 2.4rem;
	font-weight: 500;
	text-align: auto;
	width: auto;
	@media (max-width: 760px) {
		font-size: 2.1rem;
	}
`;

export const BigTitle = styled('h3')`
	color: #00195a;
	font-size: 4rem;
	font-weight: 700;
	@media (max-width: 760px) {
		font-size: 3rem;
	}
	@media (max-height: 1000px) and (max-width: 1500px) {
		font-size: 3rem;
	}
`;

export const MeasurementTitle = styled('div')`
	text-align: center;
	font-size: 5rem;
	color: #dffdf2;

	@media (min-width: 3300px) {
		font-size: 7rem;
	}
	@media (max-width: 970px) {
		font-size: 3.6rem;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		font-size: 3.2rem;
	}
`;

export const MeasurementValue = styled('div')<MeasurementValueProps>`
	font-size: ${props => (props.type === 'no-data' ? '4rem' : '15rem')};
	margin ${props => (props.type === 'no-data' ? '6rem' : '0rem')};
	text-align: center;
	color: #a0f9d9;
	font-weight: 700;
	@media (min-width: 3300px) {
		font-size: 18rem;
	}
	@media (max-width: 970px) {
		font-size: 12.4rem;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		font-size: 12rem;
	}
`;

export const MobileBlockLabel = styled('div')`
	font-size: 1.5rem;
	font-weight: 500;
	display: flex;
	justify-content: flex-end;
	color: #00195f;
`;

export const MobileMeasurementValue = styled('div')<MeasurementValueProps>`
	font-size: 2.5rem;
	color: ${props => (props.type === 'measurementTemp' ? freesiColors.iisyGreen : freesiColors.iisyBlue)};
	font-weight: 700;
	text-align: center;
`;

export const TemperatureInfo = styled('div')`
	font-size: 3rem;
	color: #dffdf2;
	@media (min-width: 3300px) {
		font-size: 4rem;
	}
	@media (max-width: 970px) {
		font-size: 2.2rem;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		font-size: 3rem;
	}
`;

export const FloorsInfo = styled('div')`
	font-size: 2rem;
	color: #dffdf2;
	margin-top: 1rem;
	@media (min-width: 3300px) {
		font-size: 3rem;
	}
	@media (max-width: 970px) {
		font-size: 1.2rem;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		font-size: 2rem;
	}
`;

export const QuestionnaireTitle = styled('div')`
	${headingText__Mobile}
	color: #00195f;
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: 1rem;
`;

export const GraphTileMeasurementTitle = styled('span')`
	${headingText__Mobile};
	text-align: center;
	margin-right: 4rem;
`;

export const MeasurementIconBox = styled('div')<DataProps>`
	width: 30%;
	display: flex;
	justify-content: ${props => (props.data ? 'flex-end' : 'center')};
	margin: ${props => (props.data ? 'none' : '0 auto')};
`;

export const QuestionLabel = styled('h3')`
	${bodyText__Mobile};
	color: #00195a;
	text-align: center;
`;

export const SummaryContentKey = styled('span')`
	font-size: 0.9rem;
	text-transform: uppercase;
	letter-spacing: 1.3px;
	width: 30%;
`;

export const SummaryContentValue = styled('span')`
	font-size: 1.2rem;
	width: 70%;
`;

export const EndText = styled('span')`
	font-size: 1.5rem;
	display: block;
	text-align: center;
	margin-top: 1rem;
`;

export const IconLabel = styled('label')`
	text-align: center;
`;

export const QuestionOptionText = styled('span')<{ isSelected?: boolean }>`
	${secondaryText__Mobile}
	text-align: center;
	font-weight: ${props => (props.isSelected ? 500 : 400)};
`;

export const DayCounterNumber = styled('span')`
	transform: translate(56%, -47%);
	position: absolute;
	top: 50%;
	right: 50%;
	color: #00195a;
	letter-spacing: 1.5px;
	font-weight: 500;
	font-size: 3rem;
`;

export const SelectionTitle = styled('h1')`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	color: #171a2b;
	font-size: 1.8rem;
	font-weight: 500;
`;

export const SummaryRowTitle = styled('span')`
	color: #050c42;
	font-size: 1.2rem;
`;

export const ErrorViewText = styled('div')`
	font-size: 1.3rem;
`;

export const SatisfactionTileText = styled('span')`
	${secondaryText__Mobile};
	text-align: center;
`;

export const PinCodeModalText = styled('p')`
	${secondaryText__Mobile}
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const ThankYouText = styled('div')`
	${secondaryText__Mobile}
	text-align: center;
	color: #00195a;
`;

/* TEXT COMPONENTS ----> */
