import { TenantNotification } from '../../models/TenantNotification';
import { InfoTvNotificationsContainer } from './infoTVStyledComponents';
import { SingleInfoTVNotification } from './SingleInfoTVNotification';

interface Props {
	notifications: TenantNotification[];
	language: string;
}

export function InfoTVNotifications({ notifications, language }: Props) {
	return (
		<InfoTvNotificationsContainer>
			{notifications.map((noti, index) => (
				<SingleInfoTVNotification notification={noti} key={index} language={language} />
			))}
		</InfoTvNotificationsContainer>
	);
}
