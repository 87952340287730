import { Button } from '@mui/material';
import styled from 'styled-components';

type ButtonProps = {
	backgroundColor: string;
	fontColor: string;
};

export const PrimaryButton = styled(Button)<ButtonProps>`
	background: ${props => props.backgroundColor};
	color: ${props => props.fontColor};
	width: fit-content;
	border-radius: 8px;
	font-size: 1.5rem;
`;

export const TextButton = styled(Button)<{ customcolor: string }>`
	color: ${props => props.customcolor};
	font-size: 1.5rem;
`;
