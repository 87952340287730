import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { TenantNotification } from '../../../models/TenantNotification';
import { FlexColumn } from '../../../styles-v1/containers';
import { getLocalizedText } from '../../../locales/LocalizedText';

interface Props {
	notification: TenantNotification;
	index: number;
	handleClickNavigation: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export function SingleNotification({ notification, index, handleClickNavigation }: Props) {
	const { language } = useTypedTranslation();

	const title = getLocalizedText(language, notification.title);
	const description = getLocalizedText(language, notification.description);
	return (
		<motion.div
			key={index}
			initial={{ opacity: 0, x: 50 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: -50 }}
			transition={{ duration: 0.4 }}
			onClick={handleClickNavigation}>
			<FlexColumn style={{ padding: '20px', alignItems: 'flex-end' }}>
				{!!notification.title.length && <Title>{title}</Title>}
				{!!notification.description?.length && <Message>{description}</Message>}
			</FlexColumn>
		</motion.div>
	);
}

const Title = styled.span`
	font-size: 18px;
	font-weight: 500;
	text-align: end;
`;

const Message = styled.p`
	font-size: 14px;
	color: #4b5563;
	text-align: end;
`;
