import { ReactKeycloakProvider } from '@react-keycloak/web';
import { isNil } from 'lodash';
import { Redirect, Route, Switch } from 'react-router';
import { ApplicationWrapper } from './ApplicationWrapper';
import { DelaySpinner } from './components/util/DelaySpinner';
import { useLoadInitialAppData } from './hooks/useLoadInitialAppData';
import { InfoTVViewV1 } from './info-tv/InfoTVViewV1';
import keycloak from './keycloack';
import { InstallContextProvider } from './stores/InstallStore';
import { useSession } from './stores/SessionStore';
import { NoContent } from './views/error/NoContent';
import { InfoView } from './views/infotv/InfoView';
import { InstallView } from './views/install/InstallView';

interface AppRouterProps {
	isBuildingQr: boolean;
}

export function AppRouter({ isBuildingQr }: AppRouterProps) {
	const { state } = useSession();
	const { isLoading } = useLoadInitialAppData(isBuildingQr);

	//TODO: separate infotv and feelis initial app state load
	const hasNoContent = !isLoading && isNil(state.dashboard);
	if (isLoading) return <DelaySpinner delay={300} />;

	return (
		<Switch>
			<Redirect exact={true} from="/" to="infotv" />
			<Route path="/install">
				<ReactKeycloakProvider
					authClient={keycloak}
					initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}>
					<InstallContextProvider>
						<InstallView />
					</InstallContextProvider>
				</ReactKeycloakProvider>
			</Route>
			<Route path="/qr">
				<ApplicationWrapper />
			</Route>
			<Route path="/infotv/:definitionId" exact>
				<InfoTVViewV1 />
			</Route>
			<Route path="/infotv">
				<InfoView />
			</Route>

			{hasNoContent && <NoContent hasNoContent={hasNoContent} />}
		</Switch>
	);
}
