import { format } from 'date-fns';
import { IdName } from '../models/Feelis';

export const renderLineAndBarGraphXLabel = (props: any) => {
	let {
		x: xOption,
		y: yOption,
		...textOptions
	} = {
		textAnchor: 'middle',
		fill: '#3E4550',
		fontSize: '1.3rem',
		x: 0,
		y: -5
	};
	xOption = xOption || 0;
	yOption = yOption || 0;
	return (
		<g transform={`translate(${props.x + xOption},${props.y + yOption})`}>
			<text x={0} y={0} dy={16} {...textOptions}>
				{format(new Date(props.payload.value), 'd.M')}
			</text>
		</g>
	);
};

export const renderFeelisYLabel = (props: any) => {
	let {
		x: xOption,
		y: yOption,
		...textOptions
	} = {
		x: 0,
		y: 3,
		textAnchor: 'end',
		fill: '#3E4550',
		fontSize: window.innerWidth > 768 ? 14 : 10
	};
	xOption = xOption || 0;
	yOption = yOption || 0;
	return (
		<g transform={`translate(${props.x + xOption},${props.y + yOption})`}>
			<text x={0} y={0} dy={0} {...textOptions}>
				{props.payload.value.toFixed(0) + '%'}
			</text>
		</g>
	);
};

export const renderYLabel = (props: any) => {
	let {
		x: xOption,
		y: yOption,
		...textOptions
	} = {
		x: 0,
		y: 0,
		textAnchor: 'end',
		fill: '#3E4550',
		fontSize: '1.3rem'
	};
	xOption = xOption || 0;
	yOption = yOption || 0;
	return (
		<g transform={`translate(${props.x + xOption},${props.y + yOption})`}>
			<text x={0} y={0} dy={0} {...textOptions}>
				{props.payload.value}
			</text>
		</g>
	);
};

export const CustomizedLabel = (data: Array<{ value: number; percentage: number }>, color: string) => (props: any) => {
	const { value, viewBox, width } = props;
	let yValue = 0;
	let fillColor = '#fff';
	if (data.find((el: any) => el.value === 0)) {
		yValue = -21;
		fillColor = color;
	}
	let { ...textOptions } = {
		textAnchor: 'middle',
		fill: fillColor,
		fontWeight: 700,
		fontSize: '1.4rem',
		letterSpacing: '0.5px',
		x: width / 2,
		y: yValue
	};
	return (
		<g transform={`translate(${viewBox.x},${viewBox.y})`}>
			<text dy={16} {...textOptions}>
				{parseFloat(value).toFixed(0)}
			</text>
		</g>
	);
};

export const makeFloorNumberArray = () => {
	const arrayToNegativeNumbers = Array(21)
		.fill(0)
		.map((v, i) => i)
		.reverse();
	const positiveArray = Array(101)
		.fill(0)
		.map((v, i) => i);

	arrayToNegativeNumbers.pop();

	const negativeArray = arrayToNegativeNumbers.map((number: number) => -number);

	return negativeArray.concat(positiveArray);
};

export interface ScoreTileBackground {
	defaultColor: string;
	sol: {
		primary: string;
		secondary: string;
	};
}
export const backgroundConfig = (defaultColor: string): ScoreTileBackground => ({
	defaultColor: defaultColor,
	sol: { primary: '#FCEBC2', secondary: '#FFFFFF' }
});

export const hasValue = (score: number | null | undefined): score is number => typeof score === 'number';

export function trimAndSort<T extends IdName>(items: T[]): T[] {
	const trimmedItems = items.map(item => ({
		...item,
		name: item.name.trim()
	}));
	return trimmedItems.sort((a, b) => a.name.localeCompare(b.name));
}
