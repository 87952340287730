import { useBrandTheme } from '../../brands/useBrandTheme';

interface Props {
	width: string;
	secondary?: boolean;
}

export function HeaderLogo({ secondary, width }: Props) {
	const { themeStyles } = useBrandTheme();
	const finalLogo = secondary ? themeStyles.secondaryLogo : themeStyles.primaryLogo;

	return (
		<img
			src={finalLogo}
			style={{ width }}
			alt="Object svg with styling not supported by browser (or svg not found, falling back...)"
		/>
	);
}
