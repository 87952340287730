import { SupportedLocale } from '../i18n/getLocaleStrings';

export interface LocalizedText {
	lang: SupportedLocale;
	value: string;
}

export type LocalizedTextList = LocalizedText[];

export function getLocalizedText(language: string, textList?: LocalizedTextList): string {
	if (!textList || !textList.length) return '';
	return textList.find(t => t.lang.toLowerCase() === language)?.value ?? textList[0].value;
}
